import React, { useState, useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";
import { Copy, Check, ExternalLink } from 'lucide-react';
import Header from '../components/Header';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { cn } from "../utils";

const noOutlineStyle = {
  outline: 'none',
  boxShadow: 'none',
  border: '1px solid #e2e8f0',
};

const linkTypes = [
  { name: 'Follow/Subscribe', value: 'follow' },
  { name: 'Share Post', value: 'share' },
];

const platforms = [
  { name: 'X (Twitter)', value: 'twitter', label: 'Enter your X (Twitter) username or profile URL', placeholder: '@username or https://twitter.com/username', badges: ['Default URL', 'Intent URL'], hasIntent: true, allowsAtSymbol: true, shareUrl: 'https://twitter.com/intent/tweet?url={url}&text={text}' },
  { name: 'YouTube', value: 'youtube', label: 'Enter your YouTube handle or channel URL', placeholder: '@YourChannel or https://www.youtube.com/@YourChannel', badges: ['Default URL', 'Intent URL'], hasIntent: true, allowsAtSymbol: true },
  { name: 'TikTok', value: 'tiktok', label: 'Enter your TikTok username or profile URL', placeholder: '@username or https://www.tiktok.com/@username', badges: ['Default URL'], hasIntent: false, allowsAtSymbol: true },
  { name: 'Instagram', value: 'instagram', label: 'Enter your Instagram username or profile URL', placeholder: 'username or https://www.instagram.com/username', badges: ['Default URL'], hasIntent: false, allowsAtSymbol: false },
  { name: 'Twitch', value: 'twitch', label: 'Enter your Twitch channel name or URL', placeholder: 'channelname or https://www.twitch.tv/channelname', badges: ['Default URL', 'Subscription URL'], hasIntent: true, allowsAtSymbol: false },
  { name: 'Facebook', value: 'facebook', label: 'Enter the URL you want to share', placeholder: 'https://example.com', badges: ['Share URL'], hasIntent: false, allowsAtSymbol: false, shareUrl: 'https://www.facebook.com/sharer/sharer.php?u={url}' },
];

const generateLink = (currentPlatform, input, useIntent, linkType, shareText = '') => {
  if (linkType === 'share') {
    const platform = platforms.find(p => p.value === currentPlatform);
    if (platform.shareUrl) {
      return platform.shareUrl
        .replace('{url}', encodeURIComponent(input))
        .replace('{text}', encodeURIComponent(shareText));
    }
    return '';
  }
  
  // Check if the input is a URL
  const isUrl = input.startsWith('http://') || input.startsWith('https://');

  if (isUrl) {
    // If it's already a URL, extract the username for Twitter
    if (currentPlatform === 'twitter') {
      const username = input.split('/').pop().replace('@', '');
      return useIntent
        ? `https://x.com/intent/follow?screen_name=${username}`
        : `https://x.com/${username}`;
    }
    
    // For other platforms, handle as before
    if (!useIntent) return input;
    
    switch (currentPlatform) {
      case 'youtube':
        return `${input}?sub_confirmation=1`;
      case 'twitch':
        return input.replace('twitch.tv/', 'twitch.tv/subs/');
      default:
        return input;
    }
  } else {
    // If it's not a URL, generate the link as before
    let username = input.startsWith('@') ? input.slice(1) : input;
    switch (currentPlatform) {
      case 'twitter':
        return useIntent
          ? `https://x.com/intent/follow?screen_name=${username}`
          : `https://x.com/${username}`;
      case 'youtube':
        return useIntent
          ? `https://www.youtube.com/@${username}?sub_confirmation=1`
          : `https://www.youtube.com/@${username}`;
      case 'tiktok':
        return `https://www.tiktok.com/@${username}`;
      case 'instagram':
        return `https://www.instagram.com/${username}`;
      case 'twitch':
        return useIntent
          ? `https://www.twitch.tv/subs/${username}`
          : `https://www.twitch.tv/${username}`;
      default:
        return '';
    }
  }
};

const getIntentMessage = (platform) => {
  switch (platform) {
    case 'twitter':
      return "Intent URLs for X (Twitter) can directly prompt users to follow you when clicked. This can increase the likelihood of gaining new followers.";
    case 'youtube':
      return "Intent URLs for YouTube include a subscription confirmation prompt, making it easier for users to subscribe to your channel when they visit the link.";
    case 'twitch':
      return "The subscription URL for Twitch directs users to your channel's subscription page, making it easier for them to support you through paid subscriptions.";
    default:
      return "";
  }
};

const FormattedInput = ({ placeholder, ...props }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  return (
    <div className="relative">
      <Input
        {...props}
        onFocus={() => setShowPlaceholder(false)}
        onBlur={(e) => setShowPlaceholder(e.target.value === '')}
        className="w-full bg-white rounded-md"
        style={noOutlineStyle}
      />
      {showPlaceholder && props.value === '' && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          {placeholder.split(' or ').map((part, index) => (
            <span key={index}>
              {index > 0 && ' or '}
              <span className="text-gray-400">
                {part.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')}
              </span>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

const SocialMediaLinkGenerator = () => {
  const [linkType, setLinkType] = useState(linkTypes[0].value);
  const [platform, setPlatform] = useState(platforms[0].value);
  const [input, setInput] = useState('');
  const [shareText, setShareText] = useState('');
  const [useIntent, setUseIntent] = useState(true);
  const [generatedLink, setGeneratedLink] = useState(() => generateLink(platforms[0].value, '', true, linkTypes[0].value));
  const [copied, setCopied] = useState(false);

  const handleLinkTypeChange = (newLinkType) => {
    setLinkType(newLinkType);
    setInput('');
    setShareText('');
    setPlatform(platforms[0].value);
    setGeneratedLink('');
  };

  const handlePlatformChange = (newPlatform) => {
    setPlatform(newPlatform);
    const newPlatformData = platforms.find(p => p.value === newPlatform);
    
    // Remove @ if switching to a platform that doesn't allow it
    let newInput = input;
    if (!newPlatformData.allowsAtSymbol && input.startsWith('@')) {
      newInput = input.slice(1);
    }
    
    setInput(newInput);
    setGeneratedLink(generateLink(newPlatform, newInput, useIntent, linkType, shareText));
  };

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    const currentPlatform = platforms.find(p => p.value === platform);
    
    let finalInput = newInput;
    
    // Remove '@' if a URL is pasted/entered
    if (newInput.startsWith('http://') || newInput.startsWith('https://')) {
      finalInput = newInput.replace('@', '');
    } else if (!currentPlatform.allowsAtSymbol) {
      finalInput = newInput.startsWith('@') ? newInput.slice(1) : newInput;
    }
    
    setInput(finalInput);
    setGeneratedLink(generateLink(platform, finalInput, useIntent, linkType, shareText));
  };

  const handleShareTextChange = (e) => {
    const newShareText = e.target.value;
    setShareText(newShareText);
    setGeneratedLink(generateLink(platform, input, useIntent, linkType, newShareText));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleIntentChange = (checked) => {
    console.log('handleIntentChange called with:', checked);
    setUseIntent(checked);
    setGeneratedLink(generateLink(platform, input, checked, linkType, shareText));
  };

  const currentPlatform = platforms.find(p => p.value === platform);

  const getBadges = (platform) => {
    return platforms.find(p => p.value === platform).badges;
  };

  return (
    <TooltipProvider>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header title="Social Media Link Generator" subtitle="Create custom follow, subscribe, or share links for various social platforms" />
        <main className="flex-grow container mx-auto px-4 py-8">
          <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Generate Your Social Media Link</h2>
            
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">Select Link Type</label>
              <Select value={linkType} onValueChange={handleLinkTypeChange}>
                <SelectTrigger className="w-full" style={noOutlineStyle}>
                  <SelectValue placeholder="Select a link type" />
                </SelectTrigger>
                <SelectContent className="bg-white border border-gray-200 shadow-lg rounded-md overflow-hidden">
                  {linkTypes.map((type) => (
                    <SelectItem key={type.value} value={type.value} className="py-2 px-4 cursor-pointer hover:bg-gray-100">
                      <span>{type.name}</span>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">Select Platform</label>
              <Select value={platform} onValueChange={handlePlatformChange}>
                <SelectTrigger className="w-full" style={noOutlineStyle}>
                  <SelectValue placeholder="Select a platform">
                    {platforms.find(p => p.value === platform)?.name}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-white border border-gray-200 shadow-lg rounded-md overflow-hidden">
                  {platforms.map((p) => (
                    <SelectItem key={p.value} value={p.value} className="py-2 px-4 cursor-pointer hover:bg-gray-100">
                      <span>{p.name}</span>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {linkType === 'share' ? 'Enter the URL you want to share' : platforms.find(p => p.value === platform)?.label}
              </label>
              <Input
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder={linkType === 'share' ? 'https://example.com' : platforms.find(p => p.value === platform)?.placeholder}
                className="w-full bg-white rounded-md"
                style={noOutlineStyle}
              />
            </div>

            {linkType === 'share' && platform === 'twitter' && (
              <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Enter share text (optional)
                </label>
                <Input
                  type="text"
                  value={shareText}
                  onChange={handleShareTextChange}
                  className="w-full bg-white rounded-md"
                  style={noOutlineStyle}
                  placeholder="Check out this link!"
                />
              </div>
            )}

            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">Generated Link</label>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <button
                      onClick={() => window.open(generatedLink, '_blank')}
                      className="text-sm text-gray-600 hover:text-gray-800 font-medium transition-colors duration-200 flex items-center"
                    >
                      Test Link
                      <ExternalLink className="h-4 w-4 ml-1" />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent className="bg-white border border-gray-200 text-gray-900 px-2 py-1 rounded shadow-md text-sm">
                    <p>Open link in new tab</p>
                  </TooltipContent>
                </Tooltip>
              </div>
              <div className="flex">
                <Input
                  type="text"
                  value={generatedLink}
                  readOnly
                  className="w-full rounded-r-none bg-white"
                  style={noOutlineStyle}
                />
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={handleCopy}
                      className="rounded-l-none bg-pink-500 hover:bg-pink-600"
                      style={noOutlineStyle}
                    >
                      {copied ? <Check className="h-4 w-4 text-white" /> : <Copy className="h-4 w-4 text-white" />}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="bg-white border border-gray-200 text-gray-900 px-2 py-1 rounded shadow-md text-sm">
                    <p>{copied ? 'Copied!' : 'Copy to clipboard'}</p>
                  </TooltipContent>
                </Tooltip>
              </div>

              {currentPlatform?.hasIntent && (
                <div className="mt-4">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="use-intent"
                      checked={useIntent}
                      onChange={(e) => handleIntentChange(e.target.checked)}
                      className="w-4 h-4 rounded"
                      style={{
                        accentColor: '#ec4899',
                        outline: 'none',
                        boxShadow: 'none',
                        border: '2px solid #ec4899'
                      }}
                    />
                    <label
                      htmlFor="use-intent"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {platform === 'twitch' ? 'Use subscription URL' : 'Use intent URL'}
                    </label>
                  </div>
                  <AnimatePresence>
                    {useIntent && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                        className="mt-2 bg-gray-100 border border-gray-200 text-gray-700 rounded-md p-3 relative"
                      >
                        <button
                          onClick={() => handleIntentChange(false)}
                          className="absolute top-1 right-1 p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
                        >
                          <X className="h-4 w-4 text-gray-500" />
                        </button>
                        <p className="text-sm pr-6">
                          {getIntentMessage(platform)}
                        </p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              )}
            </div>

            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4 text-gray-800">How to Use</h3>
              <ol className="list-decimal list-inside space-y-2 text-gray-700">
                <li>Select your desired social media platform from the dropdown menu.</li>
                <li>Enter your username or profile URL in the input field.</li>
                <li>For platforms that support it, choose whether to use an intent URL or subscription URL.</li>
                <li>Click the copy button to copy the generated link to your clipboard.</li>
                <li>Use the "Test Link" button to verify the generated link.</li>
                <li>Share the link on your website, in emails, or on other social platforms to encourage follows.</li>
              </ol>
            </div>

            <div className="mt-8 p-4 bg-gray-100 rounded-lg">
              <h3 className="text-lg font-semibold mb-2 text-gray-800">Pro Tip</h3>
              <p className="text-gray-700">
                Use intent URLs (when available) to increase the likelihood of gaining new followers. These URLs can prompt users to follow or subscribe directly when clicked.
              </p>
            </div>
          </div>
        </main>

        {/* FAQ Section */}
        <div className="container mx-auto px-4 py-8 mt-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Frequently Asked Questions</h2>
          <div className="space-y-4">
            <details className="group border-b border-gray-200">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none py-4">
                <span className="text-lg text-gray-900">What is a Social Media Link Generator?</span>
                <span className="transition group-open:rotate-180">
                  <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                </span>
              </summary>
              <p className="text-gray-600 mt-2 mb-4 group-open:animate-fadeIn">
                A Social Media Link Generator is a tool that creates custom follow links for various social media platforms. It can generate both standard profile links and special intent URLs that make it easier for people to follow you directly.
              </p>
            </details>

            <details className="group border-b border-gray-200">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none py-4">
                <span className="text-lg text-gray-900">What are intent URLs?</span>
                <span className="transition group-open:rotate-180">
                  <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                </span>
              </summary>
              <p className="text-gray-600 mt-2 mb-4 group-open:animate-fadeIn">
                Intent URLs are special links that can prompt users to perform a specific action, such as following your account or subscribing to your channel. They're available for platforms like X (Twitter), YouTube, and Twitch, and can increase the likelihood of gaining new followers.
              </p>
            </details>

            <details className="group border-b border-gray-200">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none py-4">
                <span className="text-lg text-gray-900">How do I use this Social Media Link Generator?</span>
                <span className="transition group-open:rotate-180">
                  <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                </span>
              </summary>
              <p className="text-gray-600 mt-2 mb-4 group-open:animate-fadeIn">
                To use this generator, select your social media platform, enter your username or profile URL, choose whether to use an intent URL (if available), and click the copy button to get your link. You can also test the link before using it.
              </p>
            </details>

            <details className="group border-b border-gray-200">
              <summary className="flex justify-between items-center font-medium cursor-pointer list-none py-4">
                <span className="text-lg text-gray-900">Which platforms are supported?</span>
                <span className="transition group-open:rotate-180">
                  <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                </span>
              </summary>
              <p className="text-gray-600 mt-2 mb-4 group-open:animate-fadeIn">
                This tool currently supports X (Twitter), YouTube, TikTok, Instagram, Twitch, and Facebook. Some platforms have additional features like intent URLs or subscription links.
              </p>
            </details>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default SocialMediaLinkGenerator;
