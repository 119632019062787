import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import ReactGA from "react-ga4";
import AllTools from './pages/AllTools';
import ColorPaletteGridGenerator from './pages/ColorPaletteGridGenerator';
import TextReformatter from './pages/TextReformatter';
import SocialMediaLinkGenerator from './pages/SocialMediaLinkGenerator';
import CRTPixelGenerator from './pages/CRTPixelGenerator';
import LinkExtractor from './pages/LinkExtractor';
import RouteTracker from './RouteTracker';

function App() {
  useEffect(() => {
    ReactGA.initialize("G-X0E573M55X"); // Replace with your Measurement ID
  }, []);

  return (
    <>
      <Router>
        <RouteTracker />
        <Routes>
          <Route path="/" element={<AllTools />} />
          <Route path="/link-extractor" element={<LinkExtractor />} />
          <Route path="/color-palette-grid-generator" element={<ColorPaletteGridGenerator />} />
          <Route path="/text-reformatter" element={<TextReformatter />} />
          <Route path="/social-follow-link-generator" element={<SocialMediaLinkGenerator />} />
          <Route path="/crt-pixel-generator" element={<CRTPixelGenerator />} />
          {/* Add other routes as needed */}
        </Routes>
      </Router>
      <Analytics />
    </>
  );
}

export default App;