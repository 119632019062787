import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from "./ui/button";

const CollapsibleSection = ({ title, children, onReset }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
      <div 
        className="flex justify-between items-center p-4 cursor-pointer bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-sm font-semibold">{title}</h3>
        <div className="flex items-center">
          <Button 
            onClick={(e) => { e.stopPropagation(); onReset(); }} 
            variant="outline" 
            size="sm" 
            className="text-xs py-1 px-2 h-auto mr-2"
          >
            Reset
          </Button>
          {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
      </div>
      {isOpen && <div className="px-4 pb-4">{children}</div>}
    </div>
  );
};

export default CollapsibleSection;
