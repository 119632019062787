import React from 'react';
import { ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';

const AnimatedChevron = ({ isOpen }) => (
  <motion.div
    initial={false}
    animate={{ rotate: isOpen ? 180 : 0 }}
    transition={{ duration: 0.2 }}
  >
    <ChevronDown className="h-5 w-5" />
  </motion.div>
);

export default AnimatedChevron;
