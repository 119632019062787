import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { getTools } from '../utils/toolsConfig';

export default function Header({ showText = true }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navRef = useRef(null);
  const menuRef = useRef(null);

  // Memoize the categorized tools to avoid recalculation on every render
  const toolCategories = useMemo(() => {
    const allTools = getTools();
    return allTools.reduce((acc, tool) => {
      if (!acc[tool.category]) {
        acc[tool.category] = [];
      }
      acc[tool.category].push({ name: tool.name, path: tool.path, comingSoon: tool.comingSoon });
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    const adjustMenuWidth = () => {
      if (navRef.current && menuRef.current) {
        // Set the menu width to 60% of the nav width
        const navWidth = navRef.current.offsetWidth;
        menuRef.current.style.width = `${navWidth * 0.6}px`;
        // Center the menu
        const leftOffset = navRef.current.offsetLeft + (navWidth * 0.2);
        menuRef.current.style.left = `${leftOffset}px`;
      }
    };

    adjustMenuWidth();
    window.addEventListener('resize', adjustMenuWidth);
    return () => window.removeEventListener('resize', adjustMenuWidth);
  }, []);

  const isToolActive = Object.values(toolCategories).flat().some(tool => location.pathname === tool.path);

  const linkClass = (path) => {
    if (path === '/' && isToolActive) {
      return 'text-sm font-normal transition duration-300 text-pink-600 font-semibold';
    }
    return `text-sm font-normal transition duration-300 ${
      location.pathname === path
        ? 'text-pink-600 font-semibold' 
        : 'text-gray-600 hover:text-gray-900'
    }`;
  };

  const mobileLinkClass = (path) => `block py-2 ${linkClass(path)} ${
    location.pathname === path 
      ? 'border-b-2 border-pink-600' 
      : ''
  }`;

  return (
    <header className="bg-white">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-2">
            <img 
              src="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80/uploads/publication/logo/13537081-af22-493e-a7b5-bd721d44eadd/thumb_CREATORTOOL.jpg" 
              alt="Logo" 
              className="h-10 w-10 rounded-lg" 
            />
          </Link>
          <nav ref={navRef} className="hidden md:flex flex-grow justify-center relative">
            <div className="flex items-center space-x-8">
              {/* Tools mega menu */}
              <div className="relative group">
                <button className={`${linkClass('/')} flex items-center`}>
                  Tools <ChevronDown className="ml-1 h-4 w-4" />
                </button>
                <div 
                  ref={menuRef} 
                  className="fixed mt-2 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-10 overflow-y-auto max-h-[80vh]"
                >
                  <div className="p-6">
                    {Object.entries(toolCategories).map(([category, tools]) => (
                      <div key={category} className="mb-6">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">{category}</h3>
                        <ul className="space-y-2">
                          {tools.map((tool) => (
                            <li key={tool.path}>
                              <Link
                                to={tool.path}
                                className={`text-sm ${
                                  location.pathname === tool.path
                                    ? 'text-pink-600 font-semibold'
                                    : 'text-gray-600 hover:text-gray-900 hover:underline'
                                } ${tool.comingSoon ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={tool.comingSoon ? (e) => e.preventDefault() : undefined}
                              >
                                {tool.name}
                                {tool.comingSoon && <span className="ml-2 text-xs text-gray-500">(Coming Soon)</span>}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <Link 
                to="https://creatorstoolbox.co/subscribe" 
                className={linkClass('/subscribe')}
                target="_blank"
                rel="noopener noreferrer"
              >
                Newsletter
              </Link>
              <Link 
                to="https://discord.gg/EdRBymFNFE" 
                className={linkClass('/discord')}
                target="_blank"
                rel="noopener noreferrer"
              >
                Join our Discord Server
              </Link>
            </div>
          </nav>
          <div className="md:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-600 hover:text-gray-900">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <nav className="mt-4 md:hidden">
            {/* Mobile Tools menu */}
            {Object.entries(toolCategories).map(([category, tools]) => (
              <div key={category}>
                <div className="px-4 py-2 text-sm font-semibold text-gray-700">{category}</div>
                {tools.map((tool) => (
                  <Link
                    key={tool.path}
                    to={tool.path}
                    className={mobileLinkClass(tool.path)}
                  >
                    {tool.name}
                  </Link>
                ))}
              </div>
            ))}
            <Link 
              to="https://creatorstoolbox.co/subscribe" 
              className={mobileLinkClass('/subscribe')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Newsletter
            </Link>
            <Link 
              to="https://discord.gg/WMGGx2JhRH" 
              className={mobileLinkClass('/discord')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </Link>
          </nav>
        )}
      </div>
    </header>
  )
}

