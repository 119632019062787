import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Slider } from "../components/ui/slider";
import { FileDown, Copy, Settings2, Upload, Shuffle, RefreshCw } from 'lucide-react';
import CollapsibleSection from '../components/CollapsibleSection';
import Header from '../components/Header';
import { Popover, PopoverTrigger, PopoverContent } from "../components/ui/popover";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../components/ui/select";
import { Scrollbars } from 'react-custom-scrollbars-2';

const CRTPixelGenerator = () => {
  const [image, setImage] = useState(null);
  const [pixelSize, setPixelSize] = useState(1);
  const [scanlineOpacity, setScanlineOpacity] = useState(0.3);
  const [scanlineThickness, setScanlineThickness] = useState(1);
  const [colorShift, setColorShift] = useState(0.1);
  const [curvature, setCurvature] = useState(0);
  const [curvatureCenter, setCurvatureCenter] = useState(0.5);
  const [colorTemp, setColorTemp] = useState(6500);
  const canvasRef = useRef(null);
  const placeholderRef = useRef(null);

  const [imageFormat, setImageFormat] = useState('png');
  const [imageQuality, setImageQuality] = useState('High');
  const [popoverWidth, setPopoverWidth] = useState(300);

  // Add new state for presets
  const [presets, setPresets] = useState([
    { name: "Classic CRT", pixelSize: 4, scanlineOpacity: 0.3, scanlineThickness: 1, colorShift: 0.1, curvature: 0.1, colorTemp: 6500 },
    { name: "Retro Gaming", pixelSize: 6, scanlineOpacity: 0.5, scanlineThickness: 1, colorShift: 0.2, curvature: 0.2, colorTemp: 7000 },
    { name: "Vintage TV", pixelSize: 3, scanlineOpacity: 0.4, scanlineThickness: 1, colorShift: 0.15, curvature: 0.15, colorTemp: 6000 },
    // Add more presets as needed
  ]);
  const [selectedPreset, setSelectedPreset] = useState(null);

  // New state for additional features
  const [sharpness, setSharpness] = useState(0);
  const [rgbSplit, setRgbSplit] = useState(0);
  const [vignette, setVignette] = useState(0);
  const [colorFilter, setColorFilter] = useState('none');
  const [blendMode, setBlendMode] = useState('normal');
  const [scanlineMode, setScanlineMode] = useState('horizontal');
  const [invert, setInvert] = useState(false);
  const [bloom, setBloom] = useState(0);

  // Add a new state for the random image
  const [randomImage, setRandomImage] = useState(null);

  // New state for the original and preview images
  const [originalImage, setOriginalImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  // New function to load a random image
  const loadRandomImage = useCallback(() => {
    const imageList = [
      '/images/steve-gale-yXHE8JRRQ0w-unsplash.jpg',
      '/images/senad-palic-DQEOLK4deIw-unsplash.jpg',
      '/images/moreno-matkovic-_sFS_13DBQk-unsplash.jpg',
      '/images/mike-meyers--haAxbjiHds-unsplash.jpg',
      // Add more image paths as needed
    ];

    const randomIndex = Math.floor(Math.random() * imageList.length);
    const randomImagePath = imageList[randomIndex];

    const img = new Image();
    img.onload = () => {
      setRandomImage(img);
      setOriginalImage(img);
      createPreviewImage(img);
    };
    img.src = randomImagePath;
  }, []);

  // Use useEffect to load a random image on component mount
  useEffect(() => {
    loadRandomImage();
  }, [loadRandomImage]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file instanceof Blob) {
      if (file.size > MAX_FILE_SIZE) {
        alert('File size exceeds 10MB limit. Please choose a smaller file.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          setOriginalImage(img);
          createPreviewImage(img);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      console.error('Invalid file selected');
      alert('Invalid file selected. Please choose a valid image file.');
    }
  };

  const createPreviewImage = (img) => {
    const maxPreviewSize = 800;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const scale = Math.min(maxPreviewSize / img.width, maxPreviewSize / img.height);
    
    canvas.width = img.width * scale;
    canvas.height = img.height * scale;
    
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
    const previewImg = new Image();
    previewImg.src = canvas.toDataURL();
    setPreviewImage(previewImg);
  };

  // Function to apply preset
  const applyPreset = (preset) => {
    setPixelSize(preset.pixelSize);
    setScanlineOpacity(preset.scanlineOpacity);
    setScanlineThickness(preset.scanlineThickness);
    setColorShift(preset.colorShift);
    setCurvature(preset.curvature);
    setColorTemp(preset.colorTemp);
    setSelectedPreset(preset);
    applyEffect();
  };

  const applyEffect = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    const sourceImage = isExporting ? originalImage : (previewImage || placeholderRef.current);
    if (!sourceImage) return;
    
    const maxSize = isExporting ? Math.max(sourceImage.width, sourceImage.height) : 800;
    const scale = Math.min(maxSize / sourceImage.width, maxSize / sourceImage.height);
    
    canvas.width = sourceImage.width * scale;
    canvas.height = sourceImage.height * scale;

    // Clear the canvas before drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Apply curvature
    if (curvature !== 0) {
      const curveAmount = curvature * Math.min(canvas.width, canvas.height) / 10;
      const centerX = canvas.width / 2;
      const centerY = canvas.height * curvatureCenter;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(canvas.width, 0);
      ctx.lineTo(canvas.width, canvas.height);
      ctx.lineTo(0, canvas.height);
      ctx.closePath();
      ctx.clip();

      ctx.translate(centerX, centerY);
      ctx.scale(1 + curveAmount / canvas.width, 1 + curveAmount / canvas.height);
      ctx.translate(-centerX, -centerY);
    }

    // Draw pixelated image
    ctx.imageSmoothingEnabled = false;
    const scaleFactor = Math.max(1, pixelSize);
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = canvas.width / scaleFactor;
    tempCanvas.height = canvas.height / scaleFactor;
    tempCtx.drawImage(sourceImage, 0, 0, tempCanvas.width, tempCanvas.height);
    ctx.drawImage(tempCanvas, 0, 0, tempCanvas.width, tempCanvas.height, 0, 0, canvas.width, canvas.height);

    // Apply sharpness
    if (sharpness > 0) {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        const factor = 1 + 3 * sharpness;
        data[i] = Math.min(255, Math.max(0, factor * data[i] - sharpness * (data[i+4] + data[i-4])));
        data[i+1] = Math.min(255, Math.max(0, factor * data[i+1] - sharpness * (data[i+5] + data[i-3])));
        data[i+2] = Math.min(255, Math.max(0, factor * data[i+2] - sharpness * (data[i+6] + data[i-2])));
      }
      ctx.putImageData(imageData, 0, 0);
    }

    // Apply RGB split
    if (rgbSplit > 0) {
      const splitAmount = rgbSplit * canvas.width / 100;
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const newImageData = ctx.createImageData(canvas.width, canvas.height);
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const i = (y * canvas.width + x) * 4;
          const r = imageData.data[i];
          const g = imageData.data[i + 1];
          const b = imageData.data[i + 2];
          const a = imageData.data[i + 3];
          newImageData.data[i] = r;
          newImageData.data[i + 1] = g;
          newImageData.data[i + 2] = b;
          newImageData.data[i + 3] = a;
          if (x - splitAmount >= 0) newImageData.data[((y * canvas.width + (x - splitAmount)) * 4)] = r;
          if (x + splitAmount < canvas.width) newImageData.data[((y * canvas.width + (x + splitAmount)) * 4) + 2] = b;
        }
      }
      ctx.putImageData(newImageData, 0, 0);
    }

    // Apply vignette
    if (vignette > 0) {
      const gradient = ctx.createRadialGradient(
        canvas.width / 2, canvas.height / 2, 0,
        canvas.width / 2, canvas.height / 2, Math.max(canvas.width / 2, canvas.height / 2)
      );
      gradient.addColorStop(0, 'rgba(0,0,0,0)');
      gradient.addColorStop(1, `rgba(0,0,0,${vignette})`);
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    // Apply color filter
    if (colorFilter !== 'none') {
      ctx.fillStyle = colorFilter === 'sepia' ? 'rgba(112, 66, 20, 0.5)' : 'rgba(0, 0, 0, 0.5)';
      ctx.globalCompositeOperation = colorFilter === 'grayscale' ? 'saturation' : 'multiply';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-over';
    }

    // Apply blend mode before other effects
    ctx.globalCompositeOperation = blendMode;

    // Apply scanlines
    ctx.fillStyle = `rgba(0, 0, 0, ${scanlineOpacity})`;
    if (scanlineMode === 'horizontal' || scanlineMode === 'grid') {
      for (let i = 0; i < canvas.height; i += scanlineThickness * 2) {
        ctx.fillRect(0, i, canvas.width, scanlineThickness);
      }
    }
    if (scanlineMode === 'vertical' || scanlineMode === 'grid') {
      for (let i = 0; i < canvas.width; i += scanlineThickness * 2) {
        ctx.fillRect(i, 0, scanlineThickness, canvas.height);
      }
    }

    // Apply invert
    if (invert) {
      ctx.globalCompositeOperation = 'difference';
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    // Apply bloom
    if (bloom > 0) {
      const blurRadius = bloom * 20;
      ctx.filter = `blur(${blurRadius}px)`;
      ctx.globalCompositeOperation = 'screen';
      ctx.drawImage(canvas, 0, 0);
      ctx.filter = 'none';
    }

    // Reset blend mode
    ctx.globalCompositeOperation = 'source-over';

    // Apply color shift
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      data[i] = Math.min(255, data[i] * (1 + colorShift));     // Red channel
      data[i + 2] = Math.max(0, data[i + 2] * (1 - colorShift)); // Blue channel
    }
    ctx.putImageData(imageData, 0, 0);

    // Apply color temperature
    const factor = (colorTemp - 6500) / 100;
    const r = 255 + factor * (factor < 0 ? -10 : 10);
    const b = 255 - factor * (factor < 0 ? -10 : 10);
    ctx.fillStyle = `rgba(${r}, 255, ${b}, 0.1)`;
    ctx.globalCompositeOperation = 'multiply';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = 'source-over';

    if (curvature !== 0) {
      ctx.restore();
    }
  }, [image, pixelSize, scanlineOpacity, scanlineThickness, colorShift, curvature, curvatureCenter, colorTemp, sharpness, rgbSplit, vignette, colorFilter, blendMode, scanlineMode, invert, bloom, originalImage, previewImage, isExporting]);

  useEffect(() => {
    const applyEffectIfReady = () => {
      if (previewImage || randomImage) {
        applyEffect();
      }
    };

    applyEffectIfReady();
  }, [previewImage, randomImage, applyEffect]);

  const handleExport = () => {
    setIsExporting(true);
    // Apply effect with original image
    applyEffect();
    
    // Implement export functionality here
    // ...

    setIsExporting(false);
  };

  const handleCopyImageToClipboard = () => {
    // Implement copy to clipboard functionality
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header 
        title="CRT Pixel Generator" 
        subtitle="Create retro CRT-style pixel art effects"
      />
      <main className="flex-grow flex items-center justify-center">
        <div className="container mx-auto px-4 py-8">
          <div className="w-full h-auto sm:h-[calc(100vh-120px)] flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            {/* Left sidebar */}
            <div className="w-full lg:w-1/4 bg-white rounded-lg shadow-sm overflow-hidden flex flex-col order-2 lg:order-1">
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                renderThumbVertical={({ style, ...props }) =>
                  <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                }
              >
                <div className="p-4">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-6">Settings</h2>
                  <div className="space-y-4">
                    <CollapsibleSection title="Image Upload">
                      <div className="flex space-x-2">
                        <Button
                          onClick={() => document.getElementById('imageUpload').click()}
                          className="flex-grow h-12 bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium"
                        >
                          <Upload className="mr-2 h-5 w-5" />
                          Upload Image
                        </Button>
                        <Button
                          onClick={loadRandomImage}
                          className="h-12 w-12 bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium"
                          title="Load Random Image"
                        >
                          <Shuffle className="h-5 w-5" />
                        </Button>
                        <input
                          id="imageUpload"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="hidden"
                        />
                      </div>
                      <p className="text-sm text-gray-600 mt-2">
                        Max file size: 10MB
                      </p>
                    </CollapsibleSection>

                    <CollapsibleSection title="CRT Effects">
                      <div className="space-y-4">
                        <div className="flex items-center justify-between">
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Pixel Size: {pixelSize}</Label>
                          <Button
                            onClick={() => setPixelSize(1)}
                            className="h-6 w-6 p-1 rounded-full bg-gray-200 hover:bg-gray-300"
                          >
                            <RefreshCw className="h-4 w-4" />
                          </Button>
                        </div>
                        <Slider
                          min={1}
                          max={10}
                          step={1}
                          value={[pixelSize]}
                          onValueChange={(value) => setPixelSize(value[0])}
                          className="w-full"
                        />
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Scanline Opacity: {scanlineOpacity.toFixed(2)}</Label>
                          <Slider
                            min={0}
                            max={1}
                            step={0.05}
                            value={[scanlineOpacity]}
                            onValueChange={(value) => setScanlineOpacity(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Scanline Mode</Label>
                          <Select value={scanlineMode} onValueChange={setScanlineMode}>
                            <SelectTrigger className="w-full bg-white border-gray-300">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-white border border-gray-300">
                              <SelectItem value="horizontal">Horizontal</SelectItem>
                              <SelectItem value="vertical">Vertical</SelectItem>
                              <SelectItem value="grid">Grid</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex items-center justify-between">
                          <Label className="block text-sm font-medium text-gray-700 mb-1">
                            Scanline Thickness: {scanlineThickness}px
                          </Label>
                          <Button
                            onClick={() => setScanlineThickness(1)}
                            className="h-6 w-6 p-1 rounded-full bg-gray-200 hover:bg-gray-300"
                          >
                            <RefreshCw className="h-4 w-4" />
                          </Button>
                        </div>
                        <Slider
                          min={1}
                          max={10}
                          step={1}
                          value={[scanlineThickness]}
                          onValueChange={(value) => setScanlineThickness(value[0])}
                          className="w-full"
                        />
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Curvature: {curvature.toFixed(2)}</Label>
                          <Slider
                            min={-1}
                            max={1}
                            step={0.05}
                            value={[curvature]}
                            onValueChange={(value) => setCurvature(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Curvature Center: {curvatureCenter.toFixed(2)}</Label>
                          <Slider
                            min={0}
                            max={1}
                            step={0.05}
                            value={[curvatureCenter]}
                            onValueChange={(value) => setCurvatureCenter(value[0])}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </CollapsibleSection>

                    <CollapsibleSection title="Color Adjustments">
                      <div className="space-y-4">
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Color Shift: {colorShift.toFixed(2)}</Label>
                          <Slider
                            min={0}
                            max={0.5}
                            step={0.05}
                            value={[colorShift]}
                            onValueChange={(value) => setColorShift(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Color Temperature: {colorTemp}K</Label>
                          <Slider
                            min={3000}
                            max={10000}
                            step={100}
                            value={[colorTemp]}
                            onValueChange={(value) => setColorTemp(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">RGB Split: {rgbSplit.toFixed(2)}</Label>
                          <Slider
                            min={0}
                            max={10}
                            step={0.5}
                            value={[rgbSplit]}
                            onValueChange={(value) => setRgbSplit(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Color Filter</Label>
                          <Select value={colorFilter} onValueChange={setColorFilter}>
                            <SelectTrigger className="w-full bg-white border-gray-300">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-white border border-gray-300">
                              <SelectItem value="none">None</SelectItem>
                              <SelectItem value="sepia">Sepia</SelectItem>
                              <SelectItem value="grayscale">Black & White</SelectItem>
                              <SelectItem value="invert">Invert</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            checked={invert}
                            onChange={(e) => setInvert(e.target.checked)}
                            id="invert"
                            className="form-checkbox h-5 w-5 text-pink-600"
                          />
                          <Label htmlFor="invert">Invert Colors</Label>
                        </div>
                      </div>
                    </CollapsibleSection>

                    <CollapsibleSection title="Advanced Effects">
                      <div className="space-y-4">
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Sharpness: {sharpness.toFixed(2)}</Label>
                          <Slider
                            min={0}
                            max={1}
                            step={0.05}
                            value={[sharpness]}
                            onValueChange={(value) => setSharpness(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Vignette: {vignette.toFixed(2)}</Label>
                          <Slider
                            min={0}
                            max={1}
                            step={0.05}
                            value={[vignette]}
                            onValueChange={(value) => setVignette(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Bloom: {bloom.toFixed(2)}</Label>
                          <Slider
                            min={0}
                            max={1}
                            step={0.05}
                            value={[bloom]}
                            onValueChange={(value) => setBloom(value[0])}
                            className="w-full"
                          />
                        </div>
                        <div>
                          <Label className="block text-sm font-medium text-gray-700 mb-1">Blend Mode</Label>
                          <Select value={blendMode} onValueChange={setBlendMode}>
                            <SelectTrigger className="w-full bg-white border-gray-300">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent className="bg-white border border-gray-300">
                              <SelectItem value="normal">Normal</SelectItem>
                              <SelectItem value="multiply">Multiply</SelectItem>
                              <SelectItem value="screen">Screen</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </CollapsibleSection>
                  </div>
                </div>
              </Scrollbars>

              {/* Pinned footer */}
              <div className="mt-auto p-4 border-t border-gray-200">
                <div className="relative">
                  <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                    <div className="flex-1 flex rounded-lg overflow-hidden border border-gray-300">
                      {/* Download button */}
                      <Button
                        onClick={handleExport}
                        className="flex-1 h-12 sm:h-16 text-base sm:text-lg bg-gray-100 hover:bg-gray-200 transition-colors duration-200 flex items-center justify-center px-4 sm:px-6 rounded-none"
                      >
                        <FileDown className="h-6 w-6 sm:h-8 sm:w-8 text-gray-600 mr-2 sm:mr-4 flex-shrink-0 stroke-[1.5]" />
                        <div className="flex flex-col items-start justify-center">
                          <span className="text-gray-800 font-medium leading-tight">Download</span>
                          <span className="text-xs sm:text-sm text-gray-600 leading-tight">
                            {imageQuality} as {imageFormat.toUpperCase()}
                          </span>
                        </div>
                      </Button>
                      {/* Vertical divider */}
                      <div className="w-px bg-gray-300"></div>
                      {/* Copy to Clipboard button */}
                      <Button
                        onClick={handleCopyImageToClipboard}
                        className="h-12 sm:h-16 w-12 sm:w-16 bg-gray-100 hover:bg-gray-200 transition-colors duration-200 flex items-center justify-center rounded-none"
                      >
                        <Copy className="h-5 w-5 sm:h-6 sm:w-6 text-gray-600" />
                      </Button>
                    </div>
                    {/* Settings button with Popover */}
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          className="h-12 sm:h-16 w-full sm:w-16 bg-black hover:bg-gray-900 transition-colors duration-200 rounded-lg flex items-center justify-center"
                        >
                          <Settings2 className="h-5 w-5 sm:h-6 sm:w-6 text-white" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent 
                        className="p-6 bg-gray-950 rounded-xl shadow-lg"
                        align="end"
                        side="top"
                        sideOffset={5}
                        style={{ width: `${popoverWidth}px` }}
                      >
                        <div className="space-y-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-200 mb-1">Image Format</label>
                            <Select
                              value={imageFormat}
                              onValueChange={setImageFormat}
                            >
                              <SelectTrigger className="w-full bg-gray-800 text-white border-gray-700">
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent className="bg-gray-800 text-white border border-gray-700">
                                <SelectItem value="png">PNG</SelectItem>
                                <SelectItem value="jpeg">JPEG</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-200 mb-1">Image Quality</label>
                            <Select
                              value={imageQuality}
                              onValueChange={setImageQuality}
                            >
                              <SelectTrigger className="w-full bg-gray-800 text-white border-gray-700">
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent className="bg-gray-800 text-white border border-gray-700">
                                <SelectItem value="Low">Low</SelectItem>
                                <SelectItem value="Medium">Medium</SelectItem>
                                <SelectItem value="High">High</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>

            {/* Center section with preview */}
            <div className="flex-1 order-1 lg:order-2 flex items-center justify-center">
              <div className="bg-gray-100 rounded-lg overflow-hidden w-full max-w-3xl mx-auto">
                <div className="relative" style={{ paddingBottom: '100%' }}>
                  <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full object-contain" />
                </div>
              </div>
            </div>

            {/* Right sidebar with presets */}
            <div className="w-full lg:w-1/4 bg-white rounded-lg shadow-sm overflow-hidden flex flex-col order-3">
              <div className="p-4 h-full">
                <h2 className="text-2xl font-semibold text-gray-900 mb-6">Presets</h2>
                <div className="space-y-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                  {presets.map((preset, index) => (
                    <div 
                      key={index}
                      className={`p-4 rounded-lg cursor-pointer transition-all duration-200 ${
                        selectedPreset === preset ? 'bg-pink-100 border-2 border-pink-500' : 'bg-gray-100 hover:bg-gray-200'
                      }`}
                      onClick={() => applyPreset(preset)}
                    >
                      <h3 className="font-semibold text-lg mb-2">{preset.name}</h3>
                      <div className="text-sm text-gray-600">
                        <p>Pixel Size: {preset.pixelSize}</p>
                        <p>Scanline Opacity: {preset.scanlineOpacity}</p>
                        <p>Scanline Thickness: {preset.scanlineThickness}</p>
                        <p>Color Shift: {preset.colorShift}</p>
                        <p>Curvature: {preset.curvature}</p>
                        <p>Color Temp: {preset.colorTemp}K</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CRTPixelGenerator;
