import React, { useState, useRef, useEffect } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { Parser } from 'htmlparser2';
import Header from '../components/Header';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { decode } from 'html-entities';
import { RefreshCw } from 'lucide-react'; // Import the refresh icon

const LinkExtractor = () => {
  const [input, setInput] = useState('');
  const [links, setLinks] = useState([]);
  const [outputFormat, setOutputFormat] = useState('plain');
  const [textColumnHeader, setTextColumnHeader] = useState('Text');
  const [urlColumnHeader, setUrlColumnHeader] = useState('URL');
  const [output, setOutput] = useState('');
  const editorRef = useRef(null);
  const [columns, setColumns] = useState([
    { id: 'text', title: 'Text', show: true },
    { id: 'url', title: 'URL', show: true },
    { id: 'fullText', title: 'Full Text', show: true },
    { id: 'keyword', title: 'Keyword', show: false },
    { id: 'blank', title: 'Blank', show: false },
  ]);
  const [fullTextOption, setFullTextOption] = useState('line'); // 'line', 'sentence', or 'paragraph'
  const [blankValues, setBlankValues] = useState({});
  const [keywords, setKeywords] = useState({});

  const extractLinksFromRichText = (html) => {
    const cleanHtml = DOMPurify.sanitize(html);
    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanHtml, 'text/html');
    const extractedLinks = [];

    const extractTextAndLinks = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      } else if (node.nodeName === 'A' && node.href) {
        const link = {
          url: node.href,
          text: node.textContent.trim(),
          line: node.parentNode.textContent.trim(),
          sentence: extractSentence(node),
          paragraph: extractParagraph(node)
        };
        extractedLinks.push(link);
        return link.text;
      } else {
        return Array.from(node.childNodes).map(extractTextAndLinks).join('');
      }
    };

    const extractSentence = (node) => {
      let current = node;
      let sentence = node.textContent;
      while (current.previousSibling) {
        current = current.previousSibling;
        const text = current.textContent;
        if (/[.!?]/.test(text)) {
          sentence = text.split(/[.!?]/).pop() + sentence;
          break;
        }
        sentence = text + sentence;
      }
      current = node;
      while (current.nextSibling) {
        current = current.nextSibling;
        const text = current.textContent;
        if (/[.!?]/.test(text)) {
          sentence += text.split(/[.!?]/)[0] + text.match(/[.!?]/)[0];
          break;
        }
        sentence += text;
      }
      return sentence.trim();
    };

    const extractParagraph = (node) => {
      let paragraph = node.closest('p, div, li');
      return paragraph ? paragraph.textContent.trim() : node.parentNode.textContent.trim();
    };

    extractTextAndLinks(doc.body);

    return extractedLinks;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text');
    
    if (clipboardData.getData('text/html')) {
      // Rich text with hyperlinks
      const extractedLinks = extractLinksFromRichText(pastedData);
      setLinks(extractedLinks);
      editorRef.current.innerHTML = DOMPurify.sanitize(pastedData);
    } else {
      // Plain text
      editorRef.current.innerText = pastedData;
    }
    setInput(editorRef.current.innerHTML);
    styleLinks();
  };

  const handleExtract = () => {
    setLinks([]); // Clear previous links
    if (editorRef.current) {
      const extractedLinks = extractLinksFromRichText(editorRef.current.innerHTML);
      setLinks(extractedLinks);
    }
    updateOutput();
  };

  const formatOutput = () => {
    const fullText = editorRef.current.innerText;
    switch (outputFormat) {
      case 'markdown':
        return links.map(link => {
          const linkText = `[${link.text.trim()}](${link.url})`;
          return `- ${linkText}`;
        }).join('\n');
      case 'csv':
        let csvContent = columns.filter(col => col.show).map(col => col.title).join(',') + '\n';
        csvContent += links.map((link, index) => {
          return columns.filter(col => col.show).map(col => {
            switch (col.id) {
              case 'text': return `"${link.text.trim().replace(/"/g, '""')}"`;
              case 'url': return `"${link.url.replace(/"/g, '""')}"`;
              case 'fullText': return `"${(link[fullTextOption] || '').replace(/"/g, '""')}"`;
              case 'keyword': return `"${keywords[index] || ''}"`;
              case 'blank': return `"${(blankValues[index] || '').replace(/"/g, '""')}"`;
              default: return '';
            }
          }).join(',');
        }).join('\n');
        return csvContent;
      default: // plain text
        return links.map(link => {
          let output = `• ${link.text.trim()}\n  ${link.url}`;
          return output;
        }).join('\n\n');
    }
  };

  const downloadCSV = () => {
    const csvContent = formatOutput();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'extracted_links.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const styleLinks = () => {
    if (editorRef.current) {
      const linkElements = editorRef.current.querySelectorAll('a');
      linkElements.forEach(link => {
        link.style.color = 'blue';
        link.style.textDecoration = 'underline';
        link.style.fontWeight = 'bold';
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.addEventListener('click', (e) => {
          e.preventDefault();
          window.open(link.href, '_blank', 'noopener,noreferrer');
        });
      });
    }
  };

  const updateOutput = () => {
    setOutput(formatOutput());
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColumns(items);
  };

  const toggleColumn = (id) => {
    setColumns(columns.map(col => 
      col.id === id ? { ...col, show: !col.show } : col
    ));
  };

  const handleBlankValueChange = (index, value) => {
    setBlankValues(prev => ({
      ...prev,
      [index]: value
    }));
  };

  const generateKeyword = (fullText, existingKeywords) => {
    const words = fullText
      .toUpperCase()
      .replace(/[^A-Z\s]/g, '')
      .split(/\s+/)
      .filter(word => word.length > 3);
    
    if (words.length === 0) return 'KEYWORD';

    let attempts = 0;
    let randomWord;
    do {
      randomWord = words[Math.floor(Math.random() * words.length)];
      attempts++;
      if (attempts > 100) {
        // If we can't find a unique word after 100 attempts, append a number
        randomWord += Math.floor(Math.random() * 1000);
        break;
      }
    } while (existingKeywords.includes(randomWord));

    return randomWord;
  };

  const regenerateKeywords = () => {
    const newKeywords = {};
    const existingKeywords = [];
    links.forEach((link, index) => {
      const keyword = generateKeyword(link[fullTextOption], existingKeywords);
      newKeywords[index] = keyword;
      existingKeywords.push(keyword);
    });
    setKeywords(newKeywords);
  };

  const regenerateSingleKeyword = (index) => {
    const existingKeywords = Object.values(keywords).filter((_, i) => i !== index);
    const newKeyword = generateKeyword(links[index][fullTextOption], existingKeywords);
    setKeywords(prev => ({
      ...prev,
      [index]: newKeyword
    }));
  };

  useEffect(() => {
    regenerateKeywords();
  }, [links, fullTextOption]);

  useEffect(() => {
    updateOutput();
  }, [links, outputFormat, textColumnHeader, urlColumnHeader, columns, fullTextOption, blankValues, keywords]);

  useEffect(() => {
    const currentEditorRef = editorRef.current;
    
    const handleInput = () => {
      if (currentEditorRef) {
        setInput(currentEditorRef.innerHTML);
      }
    };

    if (currentEditorRef) {
      currentEditorRef.addEventListener('input', handleInput);
      styleLinks();
    }

    return () => {
      if (currentEditorRef) {
        currentEditorRef.removeEventListener('input', handleInput);
      }
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 text-gray-900">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Link Extractor</h1>
        <div className="space-y-4">
          <div
            ref={editorRef}
            className="w-full h-40 p-2 border rounded overflow-auto"
            contentEditable
            onPaste={handlePaste}
            dangerouslySetInnerHTML={{ __html: input }}
            style={{ 
              whiteSpace: 'pre-wrap', 
              wordBreak: 'break-word',
              lineHeight: '1.5',
              fontFamily: 'Arial, sans-serif'
            }}
          />
          <Button onClick={handleExtract}>Extract Links</Button>
          <div className="flex items-center space-x-2 mb-4">
            <span>Output Format:</span>
            <Select value={outputFormat} onValueChange={setOutputFormat}>
              <SelectTrigger className="w-[180px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="plain">Plain Text</SelectItem>
                <SelectItem value="markdown">Markdown</SelectItem>
                <SelectItem value="csv">CSV</SelectItem>
              </SelectContent>
            </Select>
          </div>
          {outputFormat === 'csv' && (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                      {columns.map((column, index) => (
                        <Draggable key={column.id} draggableId={column.id} index={index}>
                          {(provided) => (
                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                className="flex items-center space-x-2 bg-white p-2 rounded shadow">
                              <input
                                type="checkbox"
                                checked={column.show}
                                onChange={() => toggleColumn(column.id)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                              />
                              <span>{column.title}</span>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
              <Button onClick={regenerateKeywords} className="mt-2">Regenerate All Keywords</Button>
              <div className="overflow-x-auto mt-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {columns.filter(col => col.show).map(column => (
                        <th key={column.id} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {column.title}
                        </th>
                      ))}
                      {columns.find(col => col.id === 'keyword' && col.show) && (
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {links.map((link, index) => (
                      <tr key={index}>
                        {columns.filter(col => col.show).map(column => (
                          <td key={column.id} className="px-6 py-4 whitespace-normal">
                            {column.id === 'text' && link.text}
                            {column.id === 'url' && (
                              <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                {link.url}
                              </a>
                            )}
                            {column.id === 'fullText' && link[fullTextOption]}
                            {column.id === 'keyword' && keywords[index]}
                            {column.id === 'blank' && (
                              <Input
                                value={blankValues[index] || ''}
                                onChange={(e) => handleBlankValueChange(index, e.target.value)}
                                className="w-full"
                              />
                            )}
                          </td>
                        ))}
                        {columns.find(col => col.id === 'keyword' && col.show) && (
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              onClick={() => regenerateSingleKeyword(index)}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <RefreshCw className="h-4 w-4" />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Button onClick={downloadCSV} className="mt-4">Download CSV</Button>
            </>
          )}
          {links.length > 0 && (
            <div>
              <h2 className="text-2xl font-semibold mb-2">Extracted Links:</h2>
              <div className="bg-white border border-gray-200 p-4 rounded-lg">
                {outputFormat === 'plain' ? (
                  <ul className="list-disc list-inside space-y-2">
                    {links.map((link, index) => (
                      <li key={index} className="mb-2">
                        <span className="font-medium">{link.text.trim()}</span>
                        <br />
                        <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                          {link.url}
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : outputFormat === 'csv' ? (
                  <>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{textColumnHeader}</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{urlColumnHeader}</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {links.map((link, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-normal">{link.text.trim()}</td>
                              <td className="px-6 py-4 whitespace-normal">
                                <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                  {link.url}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Button onClick={downloadCSV} className="mt-4">Download CSV</Button>
                  </>
                ) : (
                  <pre className="whitespace-pre-wrap overflow-x-auto max-h-60 overflow-y-auto">
                    {output}
                  </pre>
                )}
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default LinkExtractor;