import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageCircle, Unlock } from 'lucide-react';
import { Button } from "./ui/button";
import { useMediaQuery } from 'react-responsive';

const DiscordInvite = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const hasShownInvite = sessionStorage.getItem('hasShownDiscordInvite');
    if (!hasShownInvite) {
      setIsVisible(true);
      sessionStorage.setItem('hasShownDiscordInvite', 'true');
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleJoin = () => {
    window.open('https://discord.gg/EdRBymFNFE', '_blank');
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const renderStep = (step) => (
    <div className="discord-invite-container">
      <div className={`bg-white rounded-lg shadow-lg p-4 sm:p-7 ${isMobile ? 'w-full' : 'w-[400px]'} flex flex-col justify-between border border-gray-200 relative overflow-hidden`}>
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 group"
          aria-label="Close"
        >
          <div className="w-8 h-8 flex items-center justify-center relative">
            <X size={16} className="z-10" />
            <span className="absolute inset-0 bg-gray-200 rounded-full scale-0 group-hover:scale-100 transition-transform duration-200 ease-in-out" />
          </div>
        </button>
        <div className="flex flex-col items-start mb-4">
          <div className="flex mb-3 relative w-full justify-center">
            <motion.button
              onClick={() => handleStepChange(1)}
              className={`w-[24px] h-[24px] rounded-full flex items-center justify-center ${
                currentStep >= 1 ? 'bg-pink-500 text-white' : 'bg-gray-200 text-gray-600'
              } font-bold text-xs cursor-pointer z-10 mr-4`}
              whileHover={{ 
                scale: 1.03,
                y: -2,
              }}
              whileTap={{ scale: 0.97 }}
              transition={{ duration: 0.2 }}
            >
              1
            </motion.button>
            <motion.button
              onClick={() => handleStepChange(2)}
              className={`w-[24px] h-[24px] rounded-full flex items-center justify-center ${
                currentStep === 2 ? 'bg-pink-500 text-white' : 'bg-gray-200 text-gray-600'
              } font-bold text-xs cursor-pointer z-10 ml-4`}
              whileHover={{ 
                scale: 1.03,
                y: -2,
              }}
              whileTap={{ scale: 0.97 }}
              transition={{ duration: 0.2 }}
            >
              2
            </motion.button>
            <div 
              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 w-[58px] h-[2px] ${
                currentStep > 1 ? 'bg-pink-500' : 'bg-gray-200'
              } transition-colors duration-300`}
            />
          </div>
          <div className="flex flex-col items-center w-full mt-3">
            <div className="flex flex-col items-center sm:flex-row sm:items-start mb-4 h-[120px] sm:h-[80px]">
              {currentStep === 1 ? (
                <>
                  <div 
                    className="w-[50px] h-[50px] sm:w-[80px] sm:h-[80px] mb-2 sm:mb-0 sm:mr-3 flex items-center justify-center text-[50px] sm:text-[80px]"
                    role="img"
                    aria-label="Newspaper"
                  >
                    📰
                  </div>
                  <div className="flex flex-col justify-center h-full sm:h-[80px] flex-grow text-center sm:text-left">
                    <h3 className="text-xl sm:text-2xl font-bold text-gray-900 leading-tight mb-1">
                      Where <span className="underline decoration-pink-500 decoration-2 underline-offset-2 font-bold italic">creatives</span> get their news.
                    </h3>
                    <p className="text-sm text-gray-600">Join 15,000+ subscribers</p>
                  </div>
                </>
              ) : (
                <>
                  <img 
                    src="https://i.imgur.com/Pje5EfO.png" 
                    alt="Discord Logo" 
                    className="w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] mb-2 sm:mb-0 sm:mr-4 object-contain"
                  />
                  <div className="flex flex-col justify-center h-full sm:h-[80px] flex-grow text-center sm:text-left">
                    <h3 className="text-xl sm:text-2xl font-bold text-gray-900 leading-tight mb-1">
                      Join our <span className="underline decoration-pink-500 decoration-2 underline-offset-2 font-bold italic">free</span> Discord server!
                    </h3>
                    <p className="text-sm text-gray-600">1,000+ creatives & growing ⚡️</p>
                  </div>
                </>
              )}
            </div>
            <p className="text-xs sm:text-sm text-gray-600 mb-5 text-center">
              {currentStep === 1
                ? "Stay updated with the latest creative news, tools and resources!"
                : "Connect with creatives, share ideas, and get support!"}
            </p>
          </div>
        </div>
        <div className="h-[52px] z-10">
          {currentStep === 2 ? (
            <motion.div
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              animate={{ y: [0, -5, 0] }}
              transition={{ 
                y: { 
                  repeat: Infinity, 
                  duration: 1.5, 
                  ease: "easeInOut" 
                }
              }}
            >
              <Button 
                onClick={handleJoin} 
                className="w-full bg-pink-500 hover:bg-pink-600 text-white font-semibold py-3 text-lg rounded-md"
              >
                Get Access
                <Unlock className="ml-2" size={18} />
              </Button>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, y: [0, -5, 0] }}
              transition={{ 
                opacity: { duration: 0.5 },
                y: { 
                  repeat: Infinity, 
                  duration: 1.5, 
                  ease: "easeInOut" 
                }
              }}
            >
              <div className="beehiiv-embed-container" style={{ width: '100%', height: '52px' }}>
                <iframe 
                  src="https://embeds.beehiiv.com/8a130344-a11a-4844-9a14-6793514377b6?slim=true" 
                  data-test-id="beehiiv-embed" 
                  height="52" 
                  frameBorder="0" 
                  scrolling="no" 
                  style={{
                    margin: 0,
                    borderRadius: '0px !important',
                    backgroundColor: 'transparent',
                    width: '100%',
                    height: '100%',
                    border: 'none'
                  }}
                ></iframe>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ 
            opacity: 1, 
            y: 0,
            transition: {
              duration: 0.5,
              ease: "easeOut"
            }
          }}
          exit={{ opacity: 0, y: 20 }}
          className={`fixed ${isMobile ? 'bottom-0 left-0 right-0 p-4' : 'bottom-5 right-5'} z-50`}
        >
          <div className="discord-invite-wrapper">
            {renderStep(currentStep)}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DiscordInvite;