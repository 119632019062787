import { useState, useEffect, useRef, useMemo } from 'react'
import { Input } from "../components/ui/input"
import { Button } from "../components/ui/button"
import { Badge } from "../components/ui/badge"
import { Mail, Search, ChevronDown, SlidersHorizontal, RefreshCw, X, Plus, Check } from "lucide-react"
import { motion, AnimatePresence } from "framer-motion"
import Header from '../components/Header'
import DiscordInvite from '../components/DiscordInvite'
import { useNavigate, useLocation } from 'react-router-dom'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import { ChevronLeft, ChevronRight, ArrowDownWideNarrow, DollarSign, Users, Star } from "lucide-react"
import UnifiedToggleSwitch from "../components/UnifiedToggleSwitch"
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover"
import { useMediaQuery } from 'react-responsive'; // Add this import
import { Scrollbars } from 'react-custom-scrollbars-2';
import { cn } from "../utils";

// Add this constant at the top of your file, outside of any component
const PLACEHOLDER_SVG = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='320' height='180' viewBox='0 0 320 180'%3E%3Crect width='100%25' height='100%25' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' dominant-baseline='middle' text-anchor='middle' font-family='sans-serif' font-size='18' fill='%23999'%3EComing Soon%3C/text%3E%3C/svg%3E`;

// Updated mock data for the directory
export const tools = [
  { id: 1, name: "Color Grid Generator", category: "Design", tags: ["color", "grid"], thumbnail: "https://i.imgur.com/ZuZhDrd.jpeg", creator: "Creator's Toolbox", path: "/color-palette-grid-generator", description: "Create beautiful color grids for your design projects.", featured: "⚡️", trending: true, price: "free", dateAdded: "2023-05-01" },
  { id: 5, name: "Text Reformatter", category: "Utility", tags: ["text", "formatting"], thumbnail: "https://i.imgur.com/ANOeQiC.jpeg", creator: "Creator's Toolbox", path: "/text-reformatter", description: "Easily reformat and restructure your text content.", new: true, price: "free", dateAdded: "2023-09-10" },
];

const categories = [
  { name: "All", emoji: "✨", selectedClass: "bg-pink-100 text-pink-800 border-pink-300" },
  { name: "Design", emoji: "🎨", selectedClass: "bg-blue-100 text-blue-800 border-blue-300" },
  { name: "Utility", emoji: "🛠", selectedClass: "bg-orange-100 text-orange-800 border-orange-300" },
  { name: "Featured", emoji: "⚡️", selectedClass: "bg-yellow-100 text-yellow-800 border-yellow-300" },
  { name: "Trending", emoji: "📈", selectedClass: "bg-indigo-100 text-indigo-800 border-indigo-300" },
  { name: "New", emoji: "🎉", selectedClass: "bg-teal-100 text-teal-800 border-teal-300" },
];

// Update the creativeWords array
const creativeWords = [
  { word: "creative", mobile: true },
  { word: "innovative", mobile: false },
  { word: "design", mobile: true },
  { word: "freelance", mobile: false },
  { word: "underrated", mobile: false },
  { word: "hidden", mobile: true },
  { word: "marketing", mobile: false },
  { word: "social media", mobile: false },
  { word: "creator", mobile: true },
  { word: "UI/UX", mobile: true },
  { word: "3D/4D", mobile: true }
];

// Add this near the top of your file, outside of the component
const sortOptionMap = {
  "Featured": "featured",
  "Newest": "newest",
  "Oldest": "oldest",
  "A-Z": "a-z",
  "Z-A": "z-a",
  "Most Popular": "most-popular",
  "Highest Rated": "highest-rated"
};

// Inside your component
const getSortOptionDisplay = (value) => {
  return Object.keys(sortOptionMap).find(key => sortOptionMap[key] === value) || value;
};

export default function Component() {
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedCategories, setSelectedCategories] = useState(["All"])
  const [selectedTags, setSelectedTags] = useState([])
  const [email, setEmail] = useState("")
  const navigate = useNavigate()
  const location = useLocation()
  const [wordIndex, setWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const prevToolsRef = useRef();
  const [priceFilter, setPriceFilter] = useState("all")
  const [creatorFilter, setCreatorFilter] = useState("all")
  const [sortOption, setSortOption] = useState("featured")
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [expandedDropdown, setExpandedDropdown] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedPopoverTags, setSelectedPopoverTags] = useState([]);
  const [showAllTags, setShowAllTags] = useState(false);
  const [visibleTags, setVisibleTags] = useState([]);
  const tagsContainerRef = useRef(null);
  const [filterMenuWidth, setFilterMenuWidth] = useState('100%');
  const searchBarRef = useRef(null);
  const [sortButtonWidth, setSortButtonWidth] = useState(0);
  const sortButtonRef = useRef(null);

  const filteredTools = tools.filter(tool => 
    (tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     tool.creator.toLowerCase().includes(searchTerm.toLowerCase()) ||
     tool.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
     tool.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))) &&
    (selectedTags.length === 0 || selectedTags.every(tag => tool.tags.includes(tag))) &&
    (selectedPopoverTags.length === 0 || selectedPopoverTags.every(tag => tool.tags.includes(tag))) &&
    (selectedCategories.includes("All") || 
     selectedCategories.includes(tool.category) || 
     (selectedCategories.includes("Featured") && tool.featured)) &&
    (priceFilter === "all" || 
     (priceFilter === "free" && tool.price === "free") ||
     (priceFilter === "paid" && tool.price === "paid") ||
     (priceFilter === "free-and-paid" && (tool.price === "free" || tool.price === "paid" || tool.price === "free & paid"))) &&
    (creatorFilter === "all" || tool.creator === creatorFilter)
  ).sort((a, b) => {
    switch (sortOption) {
      case "featured":
        return (b.featured ? 1 : 0) - (a.featured ? 1 : 0);
      case "a-z":
        return a.name.localeCompare(b.name);
      case "z-a":
        return b.name.localeCompare(a.name);
      case "oldest":
        return new Date(a.dateAdded) - new Date(b.dateAdded);
      case "newest":
        return new Date(b.dateAdded) - new Date(a.dateAdded);
      // Add more sorting options as needed
      default:
        return 0;
    }
  });

  const handleEmailSubmit = (e) => {
    e.preventDefault()
    console.log("Email submitted:", email)
    setEmail("")
  }

  const toggleCategory = (category) => {
    setSelectedCategories(prev => {
      if (category === "All") {
        return ["All"];
      } else if (prev.includes(category)) {
        const newCategories = prev.filter(c => c !== category);
        return newCategories.length === 0 ? ["All"] : newCategories;
      } else {
        const newCategories = prev.includes("All") ? [category] : [...prev.filter(c => c !== "All"), category];
        // Check if all categories except "All" are selected
        return newCategories.length === categories.length - 1 ? ["All"] : newCategories;
      }
    });
  }

  const toggleTag = (tag, type) => {
    if (type === 'category') {
      toggleCategory(tag);
    } else if (type === 'price') {
      setPriceFilter(prev => prev === tag ? 'all' : tag);
    } else if (type === 'popover') {
      setSelectedPopoverTags(prev => 
        prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
      );
      // Update the main selectedTags state as well
      setSelectedTags(prev => 
        prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
      );
    } else {
      setSelectedTags(prev => 
        prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
      );
      // Update the popover tags state as well
      setSelectedPopoverTags(prev => 
        prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
      );
    }
  }

  const applySelectedTags = () => {
    setSelectedTags(selectedPopoverTags);
    setIsPopoverOpen(false);
  }

  const clearAllTags = () => {
    setSelectedTags([]);
    setSelectedPopoverTags([]);
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://embeds.beehiiv.com/recommendations.js?_bhpid=13537081-af22-493e-a7b5-bd721d44eadd&v=1";
    script.setAttribute('data-beehiiv-recommendations-widget', '');
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Update the getFeatureBadge function
  const getFeatureBadge = (tool) => {
    if (tool.comingSoon) return { text: "Coming Soon", emoji: "🚀", class: "bg-purple-100 text-purple-800" };
    if (tool.featured) return { text: "Featured", emoji: "⚡️", class: "bg-yellow-100 text-yellow-800" };
    if (tool.trending) return { text: "Trending", emoji: "📈", class: "bg-blue-100 text-blue-800" };
    if (tool.hot) return { text: "Hot", emoji: "🔥", class: "bg-red-100 text-red-800" };
    if (tool.new) return { text: "New", emoji: "🎉", class: "bg-green-100 text-green-800" };
    return null;
  };

  // Update the useEffect for the typewriter effect
  useEffect(() => {
    const typingInterval = 150;
    const deletingInterval = 100;
    const pauseInterval = 1000;

    const typeWriter = () => {
      const filteredWords = isMobile 
        ? creativeWords.filter(w => w.mobile).map(w => w.word)
        : creativeWords.map(w => w.word);
      
      if (filteredWords.length === 0) {
        console.warn('No words available for the current screen size');
        return;
      }

      const safeWordIndex = wordIndex % filteredWords.length;
      const fullWord = filteredWords[safeWordIndex];
      
      if (!fullWord) {
        console.warn('Unable to get a valid word');
        return;
      }

      if (!isDeleting && currentWord !== fullWord) {
        setCurrentWord(prev => fullWord.substring(0, prev.length + 1));
      } else if (isDeleting && currentWord !== "") {
        setCurrentWord(prev => prev.substring(0, prev.length - 1));
      } else if (currentWord === fullWord && !isDeleting) {
        setTimeout(() => setIsDeleting(true), pauseInterval);
      } else if (currentWord === "" && isDeleting) {
        setIsDeleting(false);
        setWordIndex(prevIndex => (prevIndex + 1) % filteredWords.length);
      }
    };

    const timer = setTimeout(typeWriter, isDeleting ? deletingInterval : typingInterval);
    return () => clearTimeout(timer);
  }, [currentWord, isDeleting, wordIndex, isMobile]);

  // Add CSS to improve text rendering and container sizing
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .gradient-text {
        line-height: 1.3; /* Increase line height for better spacing */
        overflow: visible; /* Ensure text isn't cut off */
        font-size: 1.05em; /* Slightly increase font size */
        padding: 0.2em 0; /* Add vertical padding */
      }
      .gradient-text-container {
        height: auto; /* Allow container to adjust to content */
        min-height: 1.5em; /* Ensure minimum height */
        padding: 5px 0; /* Add vertical padding to container */
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Add CSS to allow text overflow
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .overflow-visible {
        overflow: visible !important;
        white-space: nowrap !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    prevToolsRef.current = filteredTools;
  }, [filteredTools, searchTerm]);

  const isCompletelyNewList = () => {
    if (!prevToolsRef.current) return false;
    return !filteredTools.some(tool => prevToolsRef.current.some(prevTool => prevTool.id === tool.id));
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  // Update the getRelevantTags function
  const getRelevantTags = () => {
    return Array.from(new Set(filteredTools.flatMap(tool => tool.tags))).sort();
  }

  // Use useMemo to memoize the relevantTags
  const relevantTags = useMemo(() => getRelevantTags(), [filteredTools]);

  // Replace the useEffect that sets allTags with this:
  useEffect(() => {
    setAllTags(relevantTags);
  }, [relevantTags]);

  // Add these new functions
  const handleCategoryClick = (category, event) => {
    event.stopPropagation();
    toggleCategory(category);
  }

  const handleTagClick = (tag, type, event) => {
    event.stopPropagation();
    toggleTag(tag, type);
  }

  const handleFeatureClick = (feature, event) => {
    event.stopPropagation();
    toggleCategory(feature);
  }

  const handlePriceFilterChange = (value) => {
    setPriceFilter(value);
  }

  const handleCreatorFilterChange = (value) => {
    setCreatorFilter(value);
  }

  const handleSortOptionChange = (value) => {
    setSortOption(value);
  }

  // Get unique creators
  const creators = ["all", ...new Set(tools.map(tool => tool.creator))];

  // Update the price display logic in the tool card
  const getPriceDisplay = (price) => {
    switch (price) {
      case "free":
        return { text: "Free", class: "bg-green-100 text-green-800 border-green-300" };
      case "paid":
        return { text: "Paid", class: "bg-green-100 text-green-800 border-green-300" };
      case "freemium":
        return { text: "Free & Paid", class: "bg-green-100 text-green-800 border-green-300" };
      default:
        return { text: price, class: "bg-green-100 text-green-800 border-green-300" };
    }
  };

  // Add this function to get the category style
  const getCategoryStyle = (categoryName) => {
    const category = categories.find(c => c.name === categoryName);
    if (category) {
      return category.selectedClass;
    } else if (categoryName === "Utility") {
      return "bg-orange-100 text-orange-800 border-orange-300"; // Changed from green to orange
    }
    return "bg-gray-100 text-gray-800 border-gray-300";
  };

  // Define the animation variants for the popover
  // Remove the popoverVariants object as it's no longer needed

  // Add this new function to handle image errors
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = PLACEHOLDER_SVG; // Use the placeholder SVG defined at the top of the file
  };

  // Function to toggle showing all tags
  const toggleShowAllTags = () => setShowAllTags(!showAllTags);

  useEffect(() => {
    const calculateVisibleTags = () => {
      if (!tagsContainerRef.current) return;

      const containerWidth = tagsContainerRef.current.offsetWidth;
      let availableWidth = containerWidth - 120; // Subtract width of sort-by menu
      let visibleCount = 0;
      let totalWidth = 0;

      for (let i = 0; i < relevantTags.length; i++) {
        const tagWidth = estimateTagWidth(relevantTags[i]);
        if (totalWidth + tagWidth > availableWidth) break;
        totalWidth += tagWidth;
        visibleCount++;
      }

      if (visibleCount < relevantTags.length) {
        const moreTagWidth = estimateTagWidth(`+${relevantTags.length - visibleCount} more`);
        while (totalWidth + moreTagWidth > availableWidth && visibleCount > 0) {
          totalWidth -= estimateTagWidth(relevantTags[visibleCount - 1]);
          visibleCount--;
        }
      }

      setVisibleTags(relevantTags.slice(0, visibleCount));
    };

    calculateVisibleTags();
    window.addEventListener('resize', calculateVisibleTags);
    return () => window.removeEventListener('resize', calculateVisibleTags);
  }, [relevantTags]);

  const estimateTagWidth = (tag) => {
    // Rough estimate: 8px per character + 32px for padding and icons
    return tag.length * 8 + 32;
  };

  const handleToolClick = (path) => {
    navigate(path, { state: { scrollToTop: true } });
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .creative-word-container {
        display: inline-block;
        text-align: left;
      }
      .creative-word {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        border-right: 2px solid;
        animation: blink-caret 0.75s step-end infinite;
      }
      @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: currentColor; }
      }
      @media (max-width: 768px) {
        .hero-title {
          font-size: 1.75rem;
          line-height: 1.3;
        }
        .hero-subtitle {
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const updateFilterMenuWidth = () => {
      if (searchBarRef.current && isMobile) {
        setFilterMenuWidth(`${searchBarRef.current.offsetWidth}px`);
      } else {
        setFilterMenuWidth('auto');
      }
    };

    updateFilterMenuWidth();
    window.addEventListener('resize', updateFilterMenuWidth);

    return () => window.removeEventListener('resize', updateFilterMenuWidth);
  }, [isMobile]);

  useEffect(() => {
    const updateSortButtonWidth = () => {
      if (sortButtonRef.current) {
        setSortButtonWidth(sortButtonRef.current.offsetWidth);
      }
    };

    updateSortButtonWidth();
    window.addEventListener('resize', updateSortButtonWidth);

    return () => window.removeEventListener('resize', updateSortButtonWidth);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 text-gray-900">
      <Header showText={false} />
      
      {/* Hero Section */}
      <section className="py-12 md:py-20 bg-white"> {/* Changed py-12 to py-8 for mobile */}
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="hero-title text-3xl md:text-5xl font-bold mb-3 flex flex-wrap justify-center items-center" style={{ lineHeight: '1.2', paddingBottom: '0.1em' }}>
              <span>Discover the best</span>
              <span className="creative-word-container mx-2 bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text">
                <span className="creative-word">{currentWord}</span>
              </span>
              <span>tools on the internet</span>
            </h1>
            <p className="hero-subtitle text-lg md:text-xl mb-4 text-gray-600">Explore our curated selection of digital tools for creators and professionals.</p>
            <div className="flex flex-col items-center mb-4"> {/* Changed mb-6 to mb-4 */}
              <iframe 
                src="https://embeds.beehiiv.com/38ff0718-e470-4315-b547-83124ca2f2ac?slim=true&palette=dark" 
                data-test-id="beehiiv-embed" 
                height="52" 
                frameBorder="0" 
                scrolling="no" 
                style={{ margin: 0, borderRadius: '0px !important', width: '80%' }}
              ></iframe>
              <p className="text-xs text-gray-500 mt-4">Sign up for free tools & resources in your inbox every week.</p> {/* Changed mt-8 to mt-4 */}
            </div>
            {/* Remove the extra space div */}
          </div>
        </div>
      </section>

      {/* Main Content */}
      <main className="flex-1 container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Category buttons - Moved above search and filters */}
        <div className="flex flex-wrap gap-2.5 mb-5">
          {categories.map((category, index) => (
            <motion.div
              key={category.name}
              className="category-button-wrapper flex-grow sm:flex-grow-0"
              whileHover={{ 
                scale: 1.05,
                transition: { duration: 0.2, ease: "easeInOut" }
              }}
            >
              <Button
                variant={selectedCategories.includes(category.name) ? "secondary" : "outline"}
                className={`whitespace-nowrap text-base sm:text-lg py-5 px-4 sm:px-5 w-full ${
                  selectedCategories.includes(category.name)
                    ? `${category.selectedClass} border-[1px]`
                    : "bg-white text-gray-900 border-gray-300"
                } transition-all duration-200 ease-in-out category-button flex justify-center items-center`}
                onClick={() => toggleCategory(category.name)}
              >
                <span className="mr-2 sm:mr-2.5 text-lg sm:text-xl emoji-span transition-all duration-200 ease-in-out">{category.emoji}</span>
                <span className="button-text">{category.name}</span>
              </Button>
            </motion.div>
          ))}
        </div>

        {/* Search and Filters */}
        <div className="w-full mx-auto space-y-6 mb-8">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-3">
            <div className="relative flex-grow w-full sm:w-auto" ref={searchBarRef}>
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <Input
                type="search"
                placeholder="Search tools, creators, descriptions, tags..."
                className="w-full bg-white border-gray-300 h-11 rounded-md pl-11 pr-4 text-base"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ outline: 'none', boxShadow: 'none' }}
              />
            </div>
            <div className="flex space-x-3 w-full sm:w-auto">
              <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                <PopoverTrigger asChild>
                  <Button 
                    variant="outline" 
                    className="whitespace-nowrap h-11 px-4 text-base bg-white text-gray-900 font-medium border border-gray-300 hover:bg-gray-50 hover:text-gray-900 transition-colors duration-200 flex items-center flex-grow sm:flex-grow-0"
                  >
                    <SlidersHorizontal className="w-5 h-5 mr-2 stroke-2" />
                    Filters
                    <ChevronDown className="w-4 h-4 ml-2" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent 
                  align="end"
                  side="bottom"
                  sideOffset={5}
                  avoidCollisions={false}
                  className="bg-white shadow-md border border-gray-300 rounded-md p-4"
                  style={{ width: isMobile ? filterMenuWidth : 'auto' }}
                >
                  <div className="space-y-4 bg-white">
                    <div>
                      <h3 className="font-medium mb-2">Sort By</h3>
                      <Select value={sortOption} onValueChange={handleSortOptionChange}>
                        <SelectTrigger className="w-full select-trigger">
                          <SelectValue>{getSortOptionDisplay(sortOption)}</SelectValue>
                        </SelectTrigger>
                        <SelectContent className="select-content bg-white border border-gray-200 shadow-lg rounded-md overflow-hidden">
                          <Scrollbars 
                            autoHeight
                            autoHeightMax={300}
                            autoHide
                            autoHideTimeout={750}
                            autoHideDuration={300}
                            renderThumbVertical={({ style, ...props }) =>
                              <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                            }
                          >
                            <div className="py-1">
                              {Object.keys(sortOptionMap).map((option) => (
                                <SelectItem 
                                  key={sortOptionMap[option]}
                                  value={sortOptionMap[option]}
                                  className={cn(
                                    "py-2 px-4 cursor-pointer transition-colors duration-150 ease-in-out relative",
                                    "hover:bg-gray-100",
                                    sortOption === sortOptionMap[option] && "bg-gray-100"
                                  )}
                                >
                                  <div className="flex items-center justify-between w-full">
                                    <span className="font-medium text-gray-800">{option}</span>
                                    {sortOption === sortOptionMap[option] && (
                                      <Check className="h-4 w-4 text-pink-500 ml-2 flex-shrink-0" />
                                    )}
                                  </div>
                                </SelectItem>
                              ))}
                            </div>
                          </Scrollbars>
                        </SelectContent>
                      </Select>
                    </div>
                    <div>
                      <h3 className="font-medium mb-2">Creator</h3>
                      <Select value={creatorFilter} onValueChange={handleCreatorFilterChange}>
                        <SelectTrigger className="w-full select-trigger">
                          <SelectValue>{creatorFilter === "all" ? "All Creators" : creatorFilter}</SelectValue>
                        </SelectTrigger>
                        <SelectContent className="select-content bg-white border border-gray-200 shadow-lg rounded-md overflow-hidden">
                          <div className="py-1">
                            {creators.map((creator) => (
                              <SelectItem 
                                key={creator} 
                                value={creator}
                                className={cn(
                                  "py-2 px-4 cursor-pointer transition-colors duration-150 ease-in-out relative",
                                  "hover:bg-gray-100",
                                  creatorFilter === creator && "bg-gray-100"
                                )}
                              >
                                <div className="flex items-center justify-between w-full">
                                  <span className="font-medium text-gray-800">{creator === "all" ? "All Creators" : creator}</span>
                                  {creatorFilter === creator && (
                                    <Check className="h-4 w-4 text-pink-500 ml-2 flex-shrink-0" />
                                  )}
                                </div>
                              </SelectItem>
                            ))}
                          </div>
                        </SelectContent>
                      </Select>
                    </div>
                    <div>
                      <h3 className="font-medium mb-2">Price</h3>
                      <div className="flex space-x-2">
                        {["free & paid", "free", "paid"].map((price) => (
                          <Button
                            key={price}
                            variant={priceFilter === price ? "default" : "outline"}
                            onClick={() => handlePriceFilterChange(price)}
                            className={cn(
                              "transition-all duration-200 h-9 px-4",
                              priceFilter === price
                                ? "bg-black text-white hover:bg-gray-800 border border-black"
                                : "bg-white text-gray-600 hover:bg-gray-100 border border-gray-300"
                            )}
                          >
                            {price.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </Button>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h3 className="font-medium mb-2">Tags</h3>
                      <div className="flex flex-wrap gap-2 max-h-40 overflow-y-auto">
                        {relevantTags.map(tag => (
                          <Badge
                            key={tag}
                            variant={selectedPopoverTags.includes(tag) ? "default" : "outline"}
                            className={`cursor-pointer transition-all duration-200 text-xs px-2 py-1 rounded-full flex items-center h-6 border ${
                              selectedPopoverTags.includes(tag)
                                ? "bg-black text-white hover:bg-gray-800 border-black"
                                : "bg-white text-gray-600 hover:bg-gray-100 border-gray-300"
                            }`}
                            onClick={() => toggleTag(tag, 'popover')}
                          >
                            {selectedPopoverTags.includes(tag) ? (
                              <>
                                {tag}
                                <X className="w-3 h-3 ml-1 inline-block" />
                              </>
                            ) : (
                              <>
                                <Plus className="w-3 h-3 mr-1" />
                                {tag}
                              </>
                            )}
                          </Badge>
                        ))}
                      </div>
                    </div>

                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Button 
                        variant="outline"
                        className="w-full mt-4 bg-red-100 hover:bg-red-200 text-red-700 border-red-300 transition-colors duration-200 flex items-center justify-center"
                      >
                        Reset All Filters
                        <RefreshCw className="w-4 h-4 ml-2" />
                      </Button>
                    </motion.div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>

        {/* Relevant Tags Section */}
        <div className="mb-6" ref={tagsContainerRef}>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2 overflow-x-auto flex-grow">
              {visibleTags.map(tag => (
                <Badge
                  key={tag}
                  variant={selectedTags.includes(tag) ? "default" : "outline"}
                  className={`cursor-pointer transition-all duration-200 text-xs px-2 py-1 rounded-full flex items-center h-6 border whitespace-nowrap flex-shrink-0 ${
                    selectedTags.includes(tag)
                      ? "bg-black text-white hover:bg-gray-800 border-black"
                      : "bg-white text-gray-600 hover:bg-gray-100 border-gray-300"
                  }`}
                  onClick={() => toggleTag(tag, 'tag')}
                >
                  {selectedTags.includes(tag) ? (
                    <>
                      {tag}
                      <X className="w-3 h-3 ml-1 inline-block" />
                    </>
                  ) : (
                    <>
                      <Plus className="w-3 h-3 mr-1" />
                      {tag}
                    </>
                  )}
                </Badge>
              ))}
              {visibleTags.length < relevantTags.length && (
                <Badge
                  variant="outline"
                  className="cursor-pointer transition-all duration-200 text-xs px-2 py-1 rounded-full flex items-center h-6 border bg-white text-gray-600 hover:bg-gray-100 border-gray-300 whitespace-nowrap flex-shrink-0"
                  onClick={() => setIsPopoverOpen(true)}
                >
                  +{relevantTags.length - visibleTags.length} more
                </Badge>
              )}
            </div>
            <Select 
              value={sortOption} 
              onValueChange={handleSortOptionChange}
            >
              <SelectTrigger 
                className="w-[140px] h-6 md:h-7 text-xs font-medium bg-white border border-gray-300 text-gray-600 hover:bg-gray-100 transition-colors duration-200 flex-shrink-0"
                ref={sortButtonRef}
              >
                <SelectValue>{getSortOptionDisplay(sortOption)}</SelectValue>
              </SelectTrigger>
              <SelectContent 
                className="select-content bg-white border border-gray-200 shadow-lg rounded-md overflow-hidden"
                style={{ width: `${sortButtonWidth}px` }}
              >
                <Scrollbars 
                  autoHeight
                  autoHeightMax={300}
                  autoHide
                  autoHideTimeout={750}
                  autoHideDuration={300}
                  renderThumbVertical={({ style, ...props }) =>
                    <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                  }
                >
                  <div className="py-1">
                    {Object.keys(sortOptionMap).map((option) => (
                      <SelectItem 
                        key={sortOptionMap[option]}
                        value={sortOptionMap[option]}
                        className={cn(
                          "py-2 px-4 cursor-pointer transition-colors duration-150 ease-in-out relative",
                          "hover:bg-gray-100",
                          sortOption === sortOptionMap[option] && "bg-gray-100"
                        )}
                      >
                        <div className="flex items-center justify-between w-full">
                          <span className="font-medium text-gray-800">{option}</span>
                          {sortOption === sortOptionMap[option] && (
                            <Check className="h-4 w-4 text-pink-500 ml-2 flex-shrink-0" />
                          )}
                        </div>
                      </SelectItem>
                    ))}
                  </div>
                </Scrollbars>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Tools Grid - Updated for mobile */}
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6">
          {filteredTools.map(tool => (
            <motion.div
              key={tool.id}
              variants={cardVariants}
              initial={isCompletelyNewList() ? "hidden" : false}
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="bg-white rounded-lg overflow-hidden shadow-md border border-gray-200 flex flex-col"
              whileHover={{ 
                scale: 1.03,
                boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                transition: { duration: 0.2, ease: "easeInOut" }
              }}
            >
              <div 
                className="relative w-full pb-[56.25%] bg-gray-200 cursor-pointer"
                onClick={() => tool.comingSoon ? null : handleToolClick(tool.path)}
              >
                <img 
                  src={tool.thumbnail || PLACEHOLDER_SVG}
                  alt={tool.name} 
                  className="absolute inset-0 w-full h-full object-cover"
                  onError={handleImageError}
                />
                {/* Feature badge in top right corner */}
                {getFeatureBadge(tool) && (
                  <div 
                    className={`absolute top-2 right-2 px-2 py-1 text-xs font-medium rounded-full ${getFeatureBadge(tool).class}`}
                  >
                    {getFeatureBadge(tool).emoji} {getFeatureBadge(tool).text}
                  </div>
                )}
              </div>
              <div className="p-2 sm:p-4 flex-grow flex flex-col">
                <div className="flex items-baseline mb-1 sm:mb-2">
                  <h3 
                    className="text-sm sm:text-lg font-semibold cursor-pointer hover:underline mr-1 sm:mr-2 truncate"
                    onClick={() => tool.comingSoon ? null : handleToolClick(tool.path)}
                  >
                    {tool.name}
                  </h3>
                  <span className="text-xs text-gray-500 hidden sm:inline">by {tool.creator}</span>
                </div>
                <p className="text-xs sm:text-sm text-gray-600 mb-2 sm:mb-3 line-clamp-2">{tool.description}</p>
                {/* Tags section with category and price tags first */}
                <div className="mt-auto pt-2 sm:pt-3 border-t border-dashed border-gray-300">
                  <div className="flex flex-wrap gap-1 sm:gap-2 py-1">
                    {/* Category tag */}
                    <Badge
                      className={`text-[10px] sm:text-xs px-1 sm:px-2 py-0.5 sm:py-1 rounded-full flex items-center h-4 sm:h-6 border ${getCategoryStyle(tool.category)}`}
                    >
                      {tool.category}
                    </Badge>
                    {/* Price tag */}
                    <Badge
                      className={`text-[10px] sm:text-xs px-1 sm:px-2 py-0.5 sm:py-1 rounded-full flex items-center h-4 sm:h-6 border ${getPriceDisplay(tool.price).class}`}
                    >
                      {getPriceDisplay(tool.price).text}
                    </Badge>
                    {/* Other tags - hidden on mobile */}
                    {tool.tags.map(tag => (
                      <Badge
                        key={tag}
                        variant={selectedTags.includes(tag) ? "default" : "outline"}
                        className={`hidden sm:flex text-xs px-2 py-1 rounded-full items-center h-6 border ${
                          selectedTags.includes(tag)
                            ? "bg-black text-white border-black"
                            : "bg-white text-gray-600 border-gray-300"
                        }`}
                      >
                        {tag}
                      </Badge>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Add DiscordInvite component */}
        <DiscordInvite />
      </main>
    </div>
  )
}