import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
// Remove this line: import EmailGatePopup from '@EmailGatePopup.js';

const EmailGatePopup = ({ onClose, showCloseButton, toolName, iframeSrc }) => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full relative">
      {showCloseButton && (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
          onClick={onClose}
        >
          <X className="h-6 w-6" />
        </motion.button>
      )}
      <h2 className="text-2xl font-bold mb-4">Enter your email to continue</h2>
      <p className="mb-6 text-gray-600">
        Get free access to the {toolName} (and receive our weekly newsletter for creators).
      </p>
      <iframe 
        src={iframeSrc}
        data-test-id="beehiiv-embed" 
        height="52" 
        frameBorder="0" 
        scrolling="no" 
        style={{ margin: 0, borderRadius: 0, backgroundColor: 'transparent', width: '100%' }}
      ></iframe>
      <div className="flex items-start mt-6">
        <input
          type="checkbox"
          checked={true}
          disabled
          className="form-checkbox h-3 w-3 mt-[3px] mr-2 text-gray-400 border-gray-300 rounded cursor-not-allowed flex-shrink-0"
        />
        <span className="text-xs text-gray-500 leading-tight">
          By entering your email, you agree to our{' '}
          <a 
            href="/terms-of-service" 
            className="text-pink-500 hover:text-pink-600 font-medium transition-colors duration-200"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a 
            href="https://www.beehiiv.com/privacy?via=creators" 
            className="text-pink-500 hover:text-pink-600 font-medium transition-colors duration-200"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>.
        </span>
      </div>
    </div>
  );
};

const EmailGatePopupWrapper = ({ show, onClose, showCloseButton, toolName, iframeSrc }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999] px-4 sm:px-0"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ 
              scale: 1, 
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: "easeOut",
              }
            }}
            exit={{ scale: 0.9, opacity: 0 }}
          >
            <EmailGatePopup 
              onClose={onClose} 
              showCloseButton={showCloseButton} 
              toolName={toolName}
              iframeSrc={iframeSrc}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EmailGatePopupWrapper;