import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Typography, Tooltip, IconButton } from '@mui/material';
import Header from '../components/Header';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { AnimatePresence, motion } from 'framer-motion';
import { X } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import EmailGatePopupWrapper from '../components/ui/EmailGatePopup';

// Add this function at the top of your file, outside of the component
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

// Add these new functions
function addLineBreaks(text) {
  return text.replace(/([.!?])\s+/g, '$1\n').trim();
}

function removeLineBreaks(text) {
  return text.split('\n').join(' ').replace(/\s+/g, ' ').trim();
}

// Add this function at the top of your file, outside of the component
function splitIntoSentences(text) {
  // This regex looks for sentence-ending punctuation followed by spaces and a capital letter
  return text.match(/[^.!?]+[.!?]+\s+(?=[A-Z]|$)/g) || [text];
}

const TextReformatter = () => {
  const location = useLocation();
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showExample, setShowExample] = useState(true);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [findText, setFindText] = useState('');
  const [replaceText, setReplaceText] = useState('');
  const [hasUserEnteredText, setHasUserEnteredText] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [sentenceCount, setSentenceCount] = useState(2);
  const [bulletStyle, setBulletStyle] = useState('•');
  const [reformatOptionsExpanded, setReformatOptionsExpanded] = useState(true);
  const [stylingOptionsExpanded, setStylingOptionsExpanded] = useState(true);
  const [condensingRules, setCondensingRules] = useState([
    { action: 'replace', pattern: 'As per our conversation', replacement: "As discussed" },
    { action: 'replace', pattern: 'At your earliest convenience', replacement: "ASAP" },
    { action: 'replace', pattern: 'Please don\'t hesitate to contact me', replacement: "Contact me if needed" },
  ]);
  const [truncateBy, setTruncateBy] = useState('characters');
  const [truncateLimit, setTruncateLimit] = useState(100);

  const inputRef = useRef(null);

  const [isExampleApplied, setIsExampleApplied] = useState(false);

  const reformatOptions = [
    { value: 'uppercase', label: 'UPPERCASE', description: 'Convert all text to uppercase', conflicts: ['lowercase', 'capitalize'], example: 'The quick brown fox jumps over the lazy dog. Pack my box with five dozen liquor jugs.' },
    { value: 'lowercase', label: 'lowercase', description: 'Convert all text to lowercase', conflicts: ['uppercase', 'capitalize'], example: 'The Quick Brown Fox Jumps Over The Lazy Dog. Pack My Box With Five Dozen Liquor Jugs.' },
    { value: 'capitalize', label: 'Capitalize Words', description: 'Capitalize the first letter of each word', conflicts: ['uppercase', 'lowercase'], example: 'the quick brown fox jumps over the lazy dog. pack my box with five dozen liquor jugs.' },
    { value: 'reverse', label: 'Reverse Text', description: 'Reverse the order of characters in the text', conflicts: [], example: 'The quick brown fox jumps over the lazy dog. Pack my box with five dozen liquor jugs.' },
    { value: 'removeSpaces', label: 'Remove Spaces', description: 'Remove all spaces from the text', conflicts: [], example: 'The quick brown fox jumps over the lazy dog. Pack my box with five dozen liquor jugs.' },
    { value: 'findReplace', label: 'Find and Replace', description: 'Replace all occurrences of a specified text with another', conflicts: [], example: 'The quick brown fox jumps over the lazy dog. The fox is quick and the dog is lazy.', findExample: 'fox', replaceExample: 'cat' },
    { value: 'addLineBreaks', label: 'Add Line Breaks', description: 'Add line breaks after each sentence', conflicts: ['removeLineBreaks'], example: `The quick brown fox jumps over the lazy dog. Pack my box with five dozen liquor jugs. A wizard's job is to vex chumps quickly in fog.` },
    { value: 'removeLineBreaks', label: 'Remove Line Breaks', description: 'Remove all line breaks from the text', conflicts: ['addLineBreaks'], example: `The quick brown fox jumps over the lazy dog.\nPack my box with five dozen liquor jugs.\nA wizard's job is to vex chumps quickly in fog.` },
    { value: 'addBullets', label: 'Add Bullets', description: 'Add bullets to each line of text', conflicts: [], example: 'First item\nSecond item\nThird item' },
    { 
      value: 'condenseText', 
      label: 'Condense Text', 
      description: 'Condense text by removing unnecessary words and reformatting key information', 
      conflicts: [],
      example: '🔗 Apply now: Link below 👇\nPlease find attached the document you requested.\nI hope this email finds you well.'
    },
    { 
      value: 'truncate', 
      label: 'Truncate Text', 
      description: 'Truncate text to a specified length', 
      conflicts: [],
      example: 'The quick brown fox jumps over the lazy dog. Pack my box with five dozen liquor jugs. A wizard\'s job is to vex chumps quickly in fog.'
    },
  ];

  const stylingOptions = [
    { value: 'bold', label: 'B', description: 'Make text bold' },
    { value: 'italicize', label: 'I', description: 'Make text italic' },
    { value: 'underline', label: 'U', description: 'Underline the text' },
    { value: 'strikethrough', label: 'S', description: 'Add a line through the text' },
  ];

  const bulletStyles = [
    { value: '•', label: 'Dot' },
    { value: '-', label: 'Dash' },
    { value: '>', label: 'Simple Arrow' },
    { value: '→', label: 'Arrow' },
    { value: '*', label: 'Asterisk' },
    { value: '✓', label: 'Checkmark' },
  ];

  const sentences = useMemo(() => {
    return inputText.match(/[^.!?]+[.!?]+/g) || [];
  }, [inputText]);

  const maxSentences = Math.max(2, sentences.length);

  const availableOptions = useMemo(() => {
    return reformatOptions.map(option => ({
      ...option,
      disabled: (
        selectedOptions.some(selected => option.conflicts.includes(selected.value)) ||
        (option.value === 'splitParagraph' && sentences.length < 2) ||
        (option.value === 'removeLineBreaks' && !inputText.includes('\n')) ||
        (option.value === 'addLineBreaks' && inputText.includes('\n'))
      )
    }));
  }, [selectedOptions, inputText, sentences]);

  const handleToggleOption = (option) => {
    let newSelectedOptions;
    if (selectedOptions.some(selected => selected.value === option.value)) {
      newSelectedOptions = selectedOptions.filter(selected => selected.value !== option.value);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(newSelectedOptions);

    if (option.value === 'findReplace') {
      setFindText(option.findExample || '');
      setReplaceText(option.replaceExample || '');
    }
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    setHasUserEnteredText(true);
    setShowExample(false);
    setIsExampleApplied(false);
    setOutputText(handleReformat(e.target.value, selectedOptions));
  };

  const truncateText = (text, by, limit) => {
    switch (by) {
      case 'characters':
        return text.slice(0, limit) + (text.length > limit ? '...' : '');
      case 'words':
        const words = text.split(' ');
        return words.slice(0, limit).join(' ') + (words.length > limit ? '...' : '');
      case 'sentences':
        const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
        return sentences.slice(0, limit).join(' ') + (sentences.length > limit ? '...' : '');
      case 'lineBreaks':
        const lines = text.split('\n');
        return lines.slice(0, limit).join('\n') + (lines.length > limit ? '...' : '');
      default:
        return text;
    }
  };

  const handleReformat = (text, options = selectedOptions) => {
    let result = text;

    options.forEach(option => {
      switch (option.value) {
        case 'uppercase':
          result = result.toUpperCase();
          break;
        case 'lowercase':
          result = result.toLowerCase();
          break;
        case 'capitalize':
          result = result.replace(/\b\w/g, c => c.toUpperCase());
          break;
        case 'reverse':
          result = result.split('').reverse().join('');
          break;
        case 'removeSpaces':
          result = result.replace(/\s+/g, '');
          break;
        case 'findReplace':
          if (findText) {
            const regex = new RegExp(escapeRegExp(findText), 'g');
            result = result.replace(regex, replaceText);
          }
          break;
        case 'addLineBreaks':
          result = addLineBreaks(result);
          break;
        case 'removeLineBreaks':
          result = removeLineBreaks(result);
          break;
        case 'addBullets':
          result = result.split('\n').map(line => {
            const trimmedLine = line.trim();
            return trimmedLine ? `${bulletStyle} ${trimmedLine}` : '';
          }).filter(Boolean).join('\n');
          break;
        case 'condenseText':
          condensingRules.forEach(rule => {
            const regex = new RegExp(escapeRegExp(rule.pattern), 'gi');
            if (rule.action === 'delete') {
              result = result.replace(regex, '');
            } else {
              result = result.replace(regex, rule.replacement);
            }
          });
          break;
        case 'truncate':
          result = truncateText(result, truncateBy, truncateLimit);
          break;
        default:
          break;
      }
    });

    // Apply styling options after other transformations
    options.forEach(option => {
      switch (option.value) {
        case 'bold':
          result = result.split('\n').map(line => `<strong>${line}</strong>`).join('\n');
          break;
        case 'italicize':
          result = result.split('\n').map(line => `<em>${line}</em>`).join('\n');
          break;
        case 'underline':
          result = result.split('\n').map(line => `<u>${line}</u>`).join('\n');
          break;
        case 'strikethrough':
          result = result.split('\n').map(line => ` ${line}
          `).join('\n');
          break;
        default:
          break;
      }
    });

    return result;
  };

  const handleReplace = () => {
    if (findText) {
      const regex = new RegExp(escapeRegExp(findText), 'g');
      const newText = inputText.replace(regex, replaceText);
      setInputText(newText);
      setOutputText(handleReformat(newText, selectedOptions));
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(outputText).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    });
  };

  const handleSentenceCountChange = (event, newValue) => {
    setSentenceCount(Math.min(newValue, maxSentences));
  };

  const handleBulletStyleChange = (style) => {
    setBulletStyle(style);
    setOutputText(handleReformat(inputText, selectedOptions));
  };

  const handleApplyExample = () => {
    let exampleText;
    
    if (selectedOptions.some(option => option.value === 'condenseText')) {
      exampleText = `As per our conversation, I am writing to inform you about our project status. 
At your earliest convenience, please review the attached documents. 
Please don't hesitate to contact me if you have any questions.`;
    } else {
      exampleText = selectedOptions.length > 0
        ? selectedOptions[0].example
        : 'The quick brown fox jumps over the lazy dog.';
    }

    setInputText(exampleText);
    setShowExample(true);
    setHasUserEnteredText(false);
    setIsExampleApplied(true);
    setOutputText(handleReformat(exampleText, selectedOptions));
  };

  const handleClearInput = () => {
    setInputText('');
    setShowExample(false);
    setHasUserEnteredText(false);
    setOutputText('');
  };

  const handleAddRule = () => {
    setCondensingRules([...condensingRules, { action: 'replace', pattern: '', replacement: '' }]);
  };

  const handleRemoveRule = (index) => {
    const newRules = condensingRules.filter((_, i) => i !== index);
    setCondensingRules(newRules);
  };

  const handleRuleChange = (index, field, value) => {
    const newRules = [...condensingRules];
    newRules[index][field] = value;
    setCondensingRules(newRules);
  };

  const handleApplyExampleRules = () => {
    setCondensingRules([
      { action: 'replace', pattern: 'As per our conversation', replacement: "As discussed" },
      { action: 'replace', pattern: 'At your earliest convenience', replacement: "ASAP" },
      { action: 'replace', pattern: 'Please don\'t hesitate to contact me', replacement: "Contact me if needed" },
      { action: 'replace', pattern: 'I am writing to inform you', replacement: "FYI" },
      { action: 'replace', pattern: 'In light of recent developments', replacement: "Recently" },
    ]);

    // Set an example text that uses the condensing rules
    const exampleText = `As per our conversation, I am writing to inform you about our project status. 
At your earliest convenience, please review the attached documents. 
In light of recent developments, we may need to adjust our timeline. 
Please don't hesitate to contact me if you have any questions.`;

    setInputText(exampleText);
    setHasUserEnteredText(true);
    setShowExample(false);
    setOutputText(handleReformat(exampleText, [{ value: 'condenseText' }]));
  };

  useEffect(() => {
    if (location.state?.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    const formattedText = handleReformat(inputText, selectedOptions);
    setOutputText(formattedText);
  }, [inputText, selectedOptions, sentenceCount, bulletStyle, truncateBy, truncateLimit]);

  const generalFaqItems = [
    {
      question: "What is a Text Reformatter?",
      answer: "A Text Reformatter is a tool that allows you to modify and transform text in various ways, such as changing case, reversing text, or removing spaces. For example, you can turn 'Hello World' into 'HELLO WORLD' or 'dlroW olleH'."
    },
    {
      question: "How do I use this Text Reformatter?",
      answer: "1. Select one or more reformat options.\n2. Enter your text in the input field.\n3. Click the 'Reformat' button.\n4. See the transformed text in the output field.\n\nExample:\nInput: 'hello world'\nOutput (with UPPERCASE option): 'HELLO WORLD'"
    },
  ];

  const reformatOptionsFaq = reformatOptions.map(option => ({
    question: `What does the "${option.label}" option do?`,
    answer: `${option.description}.\n\nExample:\nInput: "${option.example.input || option.example}"\nOutput: "${handleReformat(option.example.input || option.example, [option])}"`
  }));

  // Add the "Find and Replace" option to the reformatOptionsFaq
  reformatOptionsFaq.push({
    question: `What does the "Find and Replace" option do?`,
    answer: `Replace all occurrences of a specified text with another.\n\nExample:\nFind: "fox"\nReplace with: "cat"\nInput: "The quick brown fox jumps over the lazy dog."\nOutput: "The quick brown cat jumps over the lazy dog."`
  });

  const renderFaqSection = (items, title) => (
    <div className="mt-8">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div className="space-y-2">
        {items.map((item, index) => (
          <div key={index} className="border border-gray-200 rounded-xl overflow-hidden">
            <button
              className="w-full text-left px-4 py-2 flex justify-between items-center focus:outline-none"
              onClick={() => toggleFaq(index)}
            >
              <span>{item.question}</span>
              <span className="text-xl">{expandedFaq === index ? '−' : '+'}</span>
            </button>
            {expandedFaq === index && (
              <div className="px-4 py-2 bg-gray-50">
                <p className="whitespace-pre-line">
                  {item.answer.split('Example:').map((part, i) => 
                    i === 0 ? part : (
                      <React.Fragment key={i}>
                        Example:
                        <div className="mt-2 p-2 bg-blue-100 border border-blue-300 rounded-md">
                          {part.trim().split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line.startsWith('Input:') || line.startsWith('Output:') ? (
                                <p>
                                  <strong>{line.split(':')[0]}:</strong>
                                  {line.split(':').slice(1).join(':')}
                                </p>
                              ) : (
                                <p>{line}</p>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </React.Fragment>
                    )
                  )}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  const toggleReformatOptions = () => {
    setReformatOptionsExpanded(!reformatOptionsExpanded);
  };

  const toggleStylingOptions = () => {
    setStylingOptionsExpanded(!stylingOptionsExpanded);
  };

  const [showEmailGate, setShowEmailGate] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);

  useEffect(() => {
    console.log("Setting timer for email gate");
    const timer = setTimeout(() => {
      console.log("Timer finished, showing email gate");
      setShowEmailGate(true);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showEmailGate) {
      const timer = setTimeout(() => {
        setShowCloseButton(true);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer);
    }
  }, [showEmailGate]);

  return (
    <>
      <Header title="Text Reformatter" subtitle="Transform your text with ease" />
      <main className="container mx-auto px-4 py-8">
        {/* Email Gate Popup */}
        <EmailGatePopupWrapper 
          show={showEmailGate} 
          onClose={() => setShowEmailGate(false)} 
          showCloseButton={showCloseButton}
          toolName="Text Reformatter"
          iframeSrc="https://embeds.beehiiv.com/87d50709-46d1-4433-b247-d70317b8f23e?slim=true"
        />

        <div className="mb-8">
          <button
            onClick={toggleReformatOptions}
            className="flex items-center justify-between w-full mb-4 pb-2 border-b"
          >
            <Typography variant="h6">Reformat Options</Typography>
            <ExpandMoreIcon className={`transform transition-transform ${reformatOptionsExpanded ? 'rotate-180' : ''}`} />
          </button>
          {reformatOptionsExpanded && (
            <div className="flex flex-wrap gap-3 pt-2">
              {availableOptions.filter(option => !stylingOptions.some(so => so.value === option.value)).map((option) => (
                <Tooltip key={option.value} title={option.description} arrow>
                  <span>
                    <button
                      onClick={() => !option.disabled && handleToggleOption(option)}
                      className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 flex items-center ${
                        selectedOptions.some(selected => selected.value === option.value)
                          ? 'bg-pink-500 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      } ${option.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                      disabled={option.disabled}
                    >
                      <span>{option.label}</span>
                      {selectedOptions.some(selected => selected.value === option.value) && (
                        <span className="ml-2">✓</span>
                      )}
                    </button>
                  </span>
                </Tooltip>
              ))}
            </div>
          )}
        </div>

        {selectedOptions.some(option => option.value === 'findReplace') && (
          <div className="mb-4">
            <div className="flex flex-col sm:flex-row gap-4 mb-2">
              <div className="flex-1">
                <label htmlFor="findText" className="block text-sm font-medium text-gray-700 mb-1">Find</label>
                <input
                  type="text"
                  id="findText"
                  value={findText}
                  onChange={(e) => setFindText(e.target.value)}
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                  placeholder="e.g., fox"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="replaceText" className="block text-sm font-medium text-gray-700 mb-1">Replace</label>
                <input
                  type="text"
                  id="replaceText"
                  value={replaceText}
                  onChange={(e) => setReplaceText(e.target.value)}
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                  placeholder="e.g., cat"
                />
              </div>
            </div>
            <button
              onClick={handleReplace}
              className="w-full px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors duration-200"
            >
              Replace
            </button>
          </div>
        )}

        {selectedOptions.some(option => option.value === 'addBullets') && (
          <div className="mb-8">
            <Typography variant="h6" className="mb-4 pb-2 border-b">Bullet Style</Typography>
            <div className="flex flex-wrap gap-3 pt-2">
              {bulletStyles.map((style) => (
                <Tooltip key={style.value} title={style.label} arrow>
                  <button
                    onClick={() => handleBulletStyleChange(style.value)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 flex items-center ${
                      bulletStyle === style.value
                        ? 'bg-pink-500 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    <span>{style.value} {style.label}</span>
                  </button>
                </Tooltip>
              ))}
            </div>
          </div>
        )}

        {selectedOptions.some(option => option.value === 'condenseText') && (
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4 pb-2 border-b">
              <Typography variant="h6">Condense Text Rules</Typography>
              <button
                onClick={handleApplyExampleRules}
                className="px-3 py-1 bg-gray-200 text-gray-700 rounded-md text-sm hover:bg-gray-300 transition-colors duration-200"
              >
                Apply Example
              </button>
            </div>
            {condensingRules.map((rule, index) => (
              <div key={index} className="flex items-center mb-4 space-x-2">
                <select
                  value={rule.action}
                  onChange={(e) => handleRuleChange(index, 'action', e.target.value)}
                  className="px-2 py-1 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                >
                  <option value="replace">Replace</option>
                  <option value="delete">Delete</option>
                </select>
                <input
                  type="text"
                  value={rule.pattern}
                  onChange={(e) => handleRuleChange(index, 'pattern', e.target.value)}
                  placeholder="Pattern"
                  className="flex-grow px-3 py-1 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                />
                {rule.action === 'replace' && (
                  <input
                    type="text"
                    value={rule.replacement}
                    onChange={(e) => handleRuleChange(index, 'replacement', e.target.value)}
                    placeholder="Replacement"
                    className="flex-grow px-3 py-1 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                  />
                )}
                <button 
                  onClick={() => handleRemoveRule(index)} 
                  className="p-1 text-gray-500 hover:text-pink-600 transition-colors duration-200"
                >
                  <DeleteOutlineIcon fontSize="small" />
                </button>
              </div>
            ))}
            <button
              onClick={handleAddRule}
              className="mt-2 px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors duration-200 flex items-center text-sm"
            >
              <AddIcon className="mr-1" /> Add Rule
            </button>
          </div>
        )}

        {selectedOptions.some(option => option.value === 'truncate') && (
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="mb-8"
          >
            <Typography variant="h6" className="mb-4 pb-2 border-b">Truncate Settings</Typography>
            <div className="flex flex-col sm:flex-row gap-4 mb-4">
              <div className="flex-1">
                <label htmlFor="truncateBy" className="block text-sm font-medium text-gray-700 mb-1">Truncate by</label>
                <select
                  id="truncateBy"
                  value={truncateBy}
                  onChange={(e) => setTruncateBy(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                >
                  <option value="characters">Characters</option>
                  <option value="words">Words</option>
                  <option value="sentences">Sentences</option>
                  <option value="lineBreaks">Line Breaks</option>
                </select>
              </div>
              <div className="flex-1">
                <label htmlFor="truncateLimit" className="block text-sm font-medium text-gray-700 mb-1">Limit</label>
                <input
                  id="truncateLimit"
                  type="number"
                  value={truncateLimit}
                  onChange={(e) => setTruncateLimit(parseInt(e.target.value))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                  min="1"
                />
              </div>
            </div>
          </motion.div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="col-span-1 relative">
            <div className="flex justify-between items-center mb-1">
              <label htmlFor="inputText" className="block text-sm font-medium text-gray-700">Input Text</label>
            </div>
            <div className="relative">
              <textarea
                ref={inputRef}
                id="inputText"
                value={inputText}
                onChange={handleInputChange}
                className="w-full h-[250px] px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent text-sm"
                style={{ resize: 'none', fontFamily: 'inherit' }}
              />
              <Tooltip title="Clear input text" arrow>
                <button
                  onClick={handleClearInput}
                  className="absolute top-2 right-2 px-2 py-1 bg-gray-200 text-gray-700 rounded-md text-xs hover:bg-gray-300 transition-colors duration-200"
                >
                  Clear
                </button>
              </Tooltip>
            </div>
            <div className="absolute bottom-2 left-2 flex space-x-2">
              {stylingOptions.map((option) => (
                <Tooltip key={option.value} title={option.description} arrow>
                  <button
                    onClick={() => handleToggleOption(option)}
                    className={`w-6 h-6 flex items-center justify-center rounded-full text-xs font-medium transition-all duration-200 ${
                      selectedOptions.some(selected => selected.value === option.value)
                        ? 'bg-pink-500 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    <span className={`
                      ${option.value === 'bold' ? 'font-bold' : ''}
                      ${option.value === 'italicize' ? 'italic' : ''}
                      ${option.value === 'underline' ? 'underline' : ''}
                      ${option.value === 'strikethrough' ? 'line-through' : ''}
                    `}>
                      {option.label}
                    </span>
                  </button>
                </Tooltip>
              ))}
            </div>
            <div className="absolute bottom-2 right-2 flex space-x-2">
              <Tooltip title="Apply example text" arrow>
                <button
                  onClick={handleApplyExample}
                  className="px-2 py-1 bg-gray-200 text-gray-700 rounded-md text-xs hover:bg-gray-300 transition-colors duration-200"
                >
                  Apply Example
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="col-span-1 relative">
            <label htmlFor="outputText" className="block text-sm font-medium text-gray-700 mb-1">Output Text</label>
            <div
              id="outputText"
              className="w-full h-[250px] px-3 py-2 text-gray-700 border rounded-lg bg-gray-100 focus:outline-none overflow-auto text-sm"
              style={{ 
                whiteSpace: 'pre-wrap', 
                wordBreak: 'break-word',
                fontFamily: 'inherit'
              }}
              dangerouslySetInnerHTML={{ __html: outputText }}
            />
            <Tooltip title="Copy to clipboard" arrow>
              <button
                onClick={handleCopyToClipboard}
                className="absolute bottom-2 right-2 p-2 bg-pink-500 text-white rounded-full hover:bg-pink-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <ContentCopyIcon fontSize="small" />
              </button>
            </Tooltip>
            {copySuccess && (
              <div className="absolute bottom-14 right-2 px-2 py-1 bg-green-500 text-white text-sm rounded-md">
                Copied!
              </div>
            )}
          </div>
        </div>

        {renderFaqSection(reformatOptionsFaq, "What do the reformat options do?")}
        {renderFaqSection(generalFaqItems, "General FAQ")}
      </main>
    </>
  );
};

export default TextReformatter;