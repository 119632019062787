"use client"

import * as React from "react"
import { RefreshCw } from "lucide-react"

const Button = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <button
      className={`inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background ${className}`}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  )
})
Button.displayName = 'Button'

function UnifiedToggleSwitch({ onValueChange }) {
  const options = ["Free", "Paid", "All"]
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const handleClick = () => {
    const nextIndex = (currentIndex + 1) % options.length
    setCurrentIndex(nextIndex)
    onValueChange?.(options[nextIndex] === "All" ? "all" : options[nextIndex].toLowerCase())
  }

  return (
    <Button
      onClick={handleClick}
      className="h-8 w-24 rounded-full bg-white hover:bg-gray-50 text-gray-900 transition-all duration-300 ease-in-out relative overflow-hidden group px-2 border border-gray-300"
    >
      <span className="flex items-center justify-center transition-all duration-300 ease-in-out">
        {options.map((option, index) => (
          <span
            key={option}
            className={`absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out text-sm ${
              index === currentIndex
                ? "opacity-100 transform translate-y-0"
                : "opacity-0 transform -translate-y-full"
            }`}
          >
            {option}
          </span>
        ))}
      </span>
      <RefreshCw className="absolute right-2 w-4 h-4 text-gray-600 group-hover:text-gray-900 transition-all duration-300 ease-in-out" />
      <span className="sr-only">Switch option</span>
    </Button>
  )
}

export default UnifiedToggleSwitch
