import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { AlertCircle, FileDown,Blend, ChevronDown, Info, Undo2, Redo2, Settings2, Check, Shuffle, ExternalLink, FileIcon, Copy, Download, ChevronUp, Maximize2, X, RotateCcw, ReplaceAll, Edit } from 'lucide-react';
import { Alert, AlertDescription } from "../components/ui/alert";  
import { saveAs } from 'file-saver';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "../components/ui/tooltip";
import GridSizeSelector from "../components/GridSizeSelector";
import { cn } from "../utils";
import { motion, AnimatePresence } from 'framer-motion';
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { DropdownMenu, DropdownMenuItem, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import Header from '../components/Header';
import { Scrollbars } from 'react-custom-scrollbars-2';
import AnimatedChevron from '../components/AnimatedChevron';
import { Loader2 } from 'lucide-react';
import useIsMobile from '../hooks/useIsMobile';
import TwoStepPopup from '../components/TwoStepPopup';
import SEO from '../components/SEO';
import EmailGatePopupWrapper from '../components/ui/EmailGatePopup';
import { useMediaQuery } from 'react-responsive';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash);
};

const DEFAULT_COLUMNS = 10;
const DEFAULT_ROWS = 10;

const presets = [
  { rows: 10, columns: 10, paletteStyle: 'monochromatic', pattern: 'linear' },
  { rows: 10, columns: 13, paletteStyle: 'harmonious', pattern: 'linear' },
  { rows: 10, columns: 11, paletteStyle: 'analogous', pattern: 'linear' },
  { rows: 15, columns: 5, paletteStyle: 'monochromatic', pattern: 'spiral' },
  { rows: 5, columns: 56, paletteStyle: 'analogous', pattern: 'linear' },
  { rows: 13, columns: 12, paletteStyle: 'earth', pattern: 'zigZag' },
  { rows: 10, columns: 10, paletteStyle: 'triadic', pattern: 'random' },
  { rows: 4, columns: 13, paletteStyle: 'monochromatic', pattern: 'spiral' },
  { rows: 7, columns: 12, paletteStyle: 'analogous', pattern: 'linear' },
  {
    rows: 5,
    columns: 5,
    paletteStyle: 'triadic', // This corresponds to "Three-Color Combo"
    pattern: 'zigZag',
    aspectRatio: '1:1',
    resolution: { width: 1920, height: 1920 }
  },
  {
    name: "Spiral Monochrome",
    rows: 10,
    columns: 10,
    paletteStyle: "monochromatic",
    pattern: "spiral",
    aspectRatio: "1:1",
    resolution: { width: 1920, height: 1920 },
  },
];

const aspectRatios = [
  { name: '16:9', width: 1920, height: 1080 },
  { name: '3:2', width: 1920, height: 1280 },
  { name: '4:3', width: 1920, height: 1440 },
  { name: '5:4', width: 1920, height: 1536 },
  { name: '1:1', width: 1920, height: 1920 },
  { name: '4:5', width: 1080, height: 1350 },
  { name: '3:4', width: 1080, height: 1440 },
  { name: '2:3', width: 1080, height: 1620 },
  { name: '9:16', width: 1080, height: 1920 },
];

const MAX_CELLS = 360; // or whatever maximum number of cells you want to allow

const MAX_CANVAS_DIMENSION = 16384; // Maximum canvas dimension supported by most browsers

// File: src/pages/ColorPaletteGridGenerator.js

const ColorGridGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pageTitle = "Color Palette Grid Generator - Creator's Toolbox";
  const pageDescription = "Create beautiful color grids for your design projects with our Color Palette Grid Generator tool.";
  const pageImage = "https://i.imgur.com/ZuZhDrd.jpeg"; // Use the thumbnail URL from AllTools.js
  const pageUrl = "https://tools.creatorstoolbox.co/color-palette-grid-generator";

  const isMobile = useIsMobile();
  const [showEmailGate, setShowEmailGate] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [showRecommendations, setShowRecommendations] = useState(false);
  
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(10);
  const [columns, setColumns] = useState(10);
  const [inputRows, setInputRows] = useState(10);
  const [inputColumns, setInputColumns] = useState(10);
  const [cellLimitReached, setCellLimitReached] = useState(false);
  const [paletteStyle, setPaletteStyle] = useState('harmonious');
  const [pattern, setPattern] = useState('zigZag');
  const [baseColors, setBaseColors] = useState([]);
  const [displayColors, setDisplayColors] = useState([]);
  const [error, setError] = useState('');
  const canvasRef = useRef(null);
  const previewWidth = 850;
  const previewHeight = 638;
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [presetColors, setPresetColors] = useState({});
  const [currentPresetIndex, setCurrentPresetIndex] = useState(null);
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [isGridInitialized, setIsGridInitialized] = useState(false);
  const [previousSettings, setPreviousSettings] = useState(null);
  const [currentSeed, setCurrentSeed] = useState('');
  const [isPresetModified, setIsPresetModified] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isPaletteTooltipOpen, setIsPaletteTooltipOpen] = useState(false);
  const [isPatternTooltipOpen, setIsPatternTooltipOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isCustomCodeTooltipOpen, setIsCustomCodeTooltipOpen] = useState(false);
  const customCodeTooltipRef = useRef(null);
  const [randomizeOption, setRandomizeOption] = useState('all');
  const [currentDownloadOption, setCurrentDownloadOption] = useState('jpg');
  const [isEditingPreset, setIsEditingPreset] = useState(false);
  const [imageFormat, setImageFormat] = useState('jpeg');
  const [imageQuality, setImageQuality] = useState('1x');
  const [baseResolution, setBaseResolution] = useState({ width: 1920, height: 1920 });
  const [outputResolution, setOutputResolution] = useState({ width: 1920, height: 1920 });
  const [aspectRatio, setAspectRatio] = useState('1:1');
  const [previewScale, setPreviewScale] = useState(1);
  const previewContainerRef = useRef(null);

  const [exportResolution, setExportResolution] = useState('1x');
  const [customWidth, setCustomWidth] = useState(outputResolution.width);
  const [customHeight, setCustomHeight] = useState(outputResolution.height);

  const paletteTooltipRef = useRef(null);
  const patternTooltipRef = useRef(null);

  const paletteStyleDefinitions = useMemo(() => ({
    monochromatic: "Colors derived from a single base hue",
    harmonious: "Colors that are adjacent on the color wheel",
    pastel: "Soft colors with low saturation from different parts of the color wheel",
    vibrant: "Fully saturated colors from evenly spaced points on the color wheel",
    analogous: "Colors that are adjacent to each other on the color wheel",
    complementary: "Colors opposite each other on the color wheel",
    triadic: "Three colors evenly spaced on the color wheel",
    earth: "Colors inspired by natural earth tones",
  }), []);

  const patternDefinitions = useMemo(() => ({
    linear: "Colors arranged in straight lines",
    diagonal: "Colors arranged diagonally",
    spiral: "Colors arranged in a spiral pattern",
    random: "Colors arranged randomly",
    zigZag: "Colors arranged in a zigzag pattern",
  }), []);

  const paletteStyleDisplayNames = useMemo(() => ({
    monochromatic: "Single Color",
    harmonious: "Matching Colors",
    pastel: "Pastel",
    vibrant: "Vibrant",
    analogous: "Similar Colors",
    complementary: "Opposite Colors",
    triadic: "Three-Color Combo",
    earth: "Earth Tones",
  }), []);

  const patternDisplayNames = useMemo(() => ({
    linear: "Straight Lines",
    diagonal: "Diagonal",
    spiral: "Spiral",
    random: "Random",
    zigZag: "Zig Zag",
  }), []);

  const colorPalettes = useMemo(() => ({
    harmonious: (count, randomFunc = Math.random) => {
      const goldenRatio = 0.618033988749895;
      let hue = randomFunc();
      return Array.from({ length: count }, () => {
        hue += goldenRatio;
        hue %= 1;
        return `hsl(${Math.floor(hue * 360)}, 70%, 50%)`;
      });
    },
    pastel: (count, randomFunc = Math.random) => Array.from({ length: count }, () => 
      `hsl(${randomFunc() * 360}, 70%, 85%)`
    ),
    vibrant: (count, randomFunc = Math.random) => Array.from({ length: count }, () => 
      `hsl(${randomFunc() * 360}, 100%, 50%)`
    ),
    monochromatic: (count, randomFunc = Math.random) => {
      const hue = randomFunc() * 360;
      return Array.from({ length: count }, (_, i) => 
        `hsl(${hue}, ${50 + i * (50 / count)}%, ${50 + i * (50 / count)}%)`
      );
    },
    analogous: (count, randomFunc = Math.random) => {
      const baseHue = randomFunc() * 360;
      return Array.from({ length: count }, (_, i) => 
        `hsl(${(baseHue + i * 30) % 360}, 70%, 50%)`
      );
    },
    complementary: (count, randomFunc = Math.random) => {
      const baseHue = randomFunc() * 360;
      const complementHue = (baseHue + 180) % 360;
      const baseColor = `hsl(${baseHue}, 70%, 50%)`;
      const complementColor = `hsl(${complementHue}, 70%, 50%)`;
      return Array.from({ length: count }, (_, i) => 
        i % 2 === 0 ? baseColor : complementColor
      );
    },
    triadic: (count, randomFunc = Math.random) => {
      const hue = randomFunc() * 360;
      return Array.from({ length: count }, (_, i) => 
        `hsl(${(hue + i * 120) % 360}, 70%, 50%)`
      );
    },
    earth: (count, randomFunc = Math.random) => Array.from({ length: count }, () => 
      `hsl(${randomFunc() * 60 + 20}, ${randomFunc() * 30 + 20}%, ${randomFunc() * 30 + 20}%)`
    ),
  }), []);

  const patterns = useMemo(() => ({
    linear: (colors, cols, rows) => colors,
    diagonal: (colors, cols, rows) => {
      const newColors = new Array(cols * rows).fill(null);
      colors.forEach((color, i) => {
        const x = i % cols;
        const y = Math.floor(i / cols);
        const newIndex = (x + y) % colors.length;
        newColors[i] = colors[newIndex];
      });
      return newColors;
    },
    spiral: (colors, cols, rows) => {
      const newColors = new Array(cols * rows).fill(null);
      let x = 0, y = 0, dx = 1, dy = 0;
      for (let i = 0; i < cols * rows; i++) {
        newColors[y * cols + x] = colors[i % colors.length];
        if (x + dx === cols || x + dx === -1 || y + dy === rows || y + dy === -1 || newColors[(y + dy) * cols + (x + dx)] !== null) {
          [dx, dy] = [-dy, dx];
        }
        x += dx;
        y += dy;
      }
      return newColors;
    },
    random: (colors, cols, rows, seed) => {
      const randomFunc = seed 
        ? () => (seed = (seed * 1664525 + 1013904223) % 4294967296) / 4294967296
        : Math.random;
      return Array.from({ length: cols * rows }, () => colors[Math.floor(randomFunc() * colors.length)]);
    },
    zigZag: (colors, columns, rows) => {
      const result = new Array(rows * columns);
      let colorIndex = 0;

      for (let row = 0; row < rows; row++) {
        const isEvenRow = row % 2 === 0;
        for (let col = 0; col < columns; col++) {
          const actualCol = isEvenRow ? col : columns - 1 - col;
          const index = row * columns + actualCol;
          result[index] = colors[colorIndex % colors.length];
          colorIndex++;
        }
      }

      return result;
    },
  }), []);

  const [colorType, setColorType] = useState('default');

  // Add this new state to store unique colors
  const [uniqueColors, setUniqueColors] = useState([]);

  // Add this new state to store the original colors
  const [originalColors, setOriginalColors] = useState({ baseColors: [], displayColors: [] });

  // Move handleColorTypeChange inside the component
  const handleColorTypeChange = useCallback((newColorType) => {
    if (newColorType === 'custom') {
      setColorType('custom');
      // When switching to custom, keep the current colors
      setUniqueColors(Array.from(new Set(displayColors)));
    } else {
      setColorType('default');
      // When resetting to default, use the original colors
      setBaseColors(originalColors.baseColors);
      setDisplayColors(originalColors.displayColors);
      setUniqueColors(Array.from(new Set(originalColors.displayColors)));
    }
  }, [displayColors, originalColors]);

  // Modify the handleBaseColorChange function
  const handleBaseColorChange = (index, color) => {
    const newUniqueColors = [...uniqueColors];
    newUniqueColors[index] = color;
    setUniqueColors(newUniqueColors);
    
    // Update the displayColors based on the new unique colors
    const newDisplayColors = displayColors.map(oldColor => 
      newUniqueColors[uniqueColors.indexOf(oldColor)] || oldColor
    );
    setDisplayColors(newDisplayColors);
    setBaseColors(newUniqueColors);
  };

  // Modify the generateColors function to set unique colors and original colors
  const generateColors = useCallback((rows, cols, style, patternType, customBaseColors = null, seed = null) => {
    console.log('Generating colors:', rows, cols, style, patternType, customBaseColors, seed);
    setIsLoading(true);
    const randomFunction = seed !== null
      ? () => (seed = (seed * 1664525 + 1013904223) % 4294967296) / 4294967296
      : Math.random;
    
    let generatedBaseColors;
    if (customBaseColors) {
      generatedBaseColors = customBaseColors;
    } else if (rows === 1 && cols === 1) {
      const hue = randomFunction() * 360;
      generatedBaseColors = [`hsl(${hue}, 70%, 50%)`];
    } else {
      generatedBaseColors = colorPalettes[style] ? colorPalettes[style](rows * cols, randomFunction) : Array(rows * cols).fill('#000000');
    }
    
    const displayColors = patterns[patternType] ? patterns[patternType](generatedBaseColors, cols, rows, seed) : generatedBaseColors;
    
    // Set unique colors
    const uniqueColorSet = new Set(displayColors);
    setUniqueColors(Array.from(uniqueColorSet));

    setIsLoading(false);
    console.log('Generated colors:', generatedBaseColors, displayColors);

    // Store the original colors
    setOriginalColors({ baseColors: generatedBaseColors, displayColors });

    return { baseColors: generatedBaseColors, displayColors };
  }, [colorPalettes, patterns]);

  const generatePresetColors = useCallback(() => {
    const newPresetColors = {};
    presets.forEach((preset, index) => {
      try {
        const seed = hashString(`preset-${index}`);
        const { baseColors, displayColors } = generateColors(preset.rows, preset.columns, preset.paletteStyle, preset.pattern, null, seed);
        newPresetColors[index] = { baseColors, displayColors, seed };
      } catch (error) {
        console.error(`Error generating preset ${index}:`, error);
        newPresetColors[index] = { error: true };
      }
    });
    setPresetColors(newPresetColors);
    return newPresetColors;
  }, [generateColors]);

  useEffect(() => {
    console.log("Component mounted");
    setIsComponentMounted(true);

    // Generate random settings
    const randomRows = 10;
    const randomColumns = 10;
    const randomPaletteStyle = getRandomPaletteStyle();
    const randomPattern = getRandomPattern();

    console.log('Initial random settings:', {
      rows: randomRows,
      columns: randomColumns,
      style: randomPaletteStyle,
      pattern: randomPattern
    });

    // Apply random settings
    setRows(randomRows);
    setColumns(randomColumns);
    setInputRows(randomRows);
    setInputColumns(randomColumns);
    setPaletteStyle(randomPaletteStyle);
    setPattern(randomPattern);

    // Set 1:1 aspect ratio on page load
    handleAspectRatioChange('1:1');

    // Generate preset colors after setting initial state
    const initialPresetColors = generatePresetColors();
    setPresetColors(initialPresetColors);

    setIsLoading(false);

    // Collapse all sections on mobile
    if (isMobile) {
      setExpandedSections({
        gridSize: false,
        color: false,
        pattern: false,
        export: false,
        aspectRatio: false,
      });
    }
  }, [generatePresetColors, isMobile]);

  useEffect(() => {
    console.log("Setting timer for email gate");
    const timer = setTimeout(() => {
      console.log("Timer finished, showing email gate");
      setShowEmailGate(true);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showEmailGate) {
      const timer = setTimeout(() => {
        setShowCloseButton(true);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer);
    }
  }, [showEmailGate]);

  useEffect(() => {
    if (isComponentMounted && !isGridInitialized) {
      console.log("Generating initial colors");
      const { baseColors, displayColors } = generateColors(rows, columns, paletteStyle, pattern);
      
      console.log('Generated colors:', {
        baseColors: baseColors.slice(0, 5),
        displayColors: displayColors.slice(0, 5)
      });

      setBaseColors(baseColors);
      setDisplayColors(displayColors);
      setIsGridInitialized(true);
    }
  }, [isComponentMounted, isGridInitialized, rows, columns, paletteStyle, pattern, generateColors]);

  useEffect(() => {
    console.log("displayColors updated:", displayColors);
    if (canvasRef.current) {
      renderColors(displayColors, rows, columns);
    }
  }, [displayColors, rows, columns]);

  const renderColors = useCallback((colors, rows, cols) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (colors.length === 0) return;

    if (rows === 1 && cols === 1) {
      // For 1x1 grid, fill the entire canvas with the single color
      ctx.fillStyle = colors[0];
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    } else {
      const cellWidth = canvas.width / cols;
      const cellHeight = canvas.height / rows;
      const totalWidth = cellWidth * cols;
      const totalHeight = cellHeight * rows;
      const offsetX = (canvas.width - totalWidth) / 2;
      const offsetY = (canvas.height - totalHeight) / 2;

      colors.forEach((color, index) => {
        const x = offsetX + (index % cols) * cellWidth;
        const y = offsetY + Math.floor(index / cols) * cellHeight;
        ctx.fillStyle = color;
        ctx.fillRect(x, y, cellWidth, cellHeight);
      });
    }
  }, []);

  const generateSeedFromSettings = useCallback((settings) => {
    return btoa(JSON.stringify({
      rows: settings.rows,
      columns: settings.columns,
      paletteStyle: settings.paletteStyle,
      pattern: settings.pattern
    }));
  }, []);

  const applySettings = useCallback((settings) => {
    console.log('Applying settings:', settings);
  
    setRows(settings.rows);
    setColumns(settings.columns);
    setPaletteStyle(settings.paletteStyle);
    setPattern(settings.pattern);
    
    setBaseColors(settings.baseColors);
    setDisplayColors(settings.displayColors);
    setCurrentSeed(settings.seed);

    setInputRows(settings.rows);
    setInputColumns(settings.columns);

    if (canvasRef.current) {
      console.log("Rendering colors on canvas");
      renderColors(settings.displayColors, settings.rows, settings.columns);
    }

    console.log("Settings applied successfully");
  }, [renderColors]);

  // Add this new state to track if a preset is selected
  const [isPresetSelected, setIsPresetSelected] = useState(false);

  // Modify the handlePresetClick function
  const handlePresetClick = useCallback((index) => {
    if (currentPresetIndex === index) {
      // De-select the preset
      setCurrentPresetIndex(null);
      setIsEditingPreset(false);
      setIsPresetModified(false);
      setIsPresetSelected(false);
      
      // Revert to previous settings if available
      if (previousSettings) {
        applySettings(previousSettings);
        setPreviousSettings(null);
      }
    } else {
      // Store current settings before applying the preset
      setPreviousSettings({
        rows,
        columns,
        paletteStyle,
        pattern,
        baseColors,
        displayColors,
      });

      setCurrentPresetIndex(index);
      setIsEditingPreset(true);
      setIsPresetModified(false);
      setIsPresetSelected(true);
      
      // Apply preset settings
      const preset = presets[index];
      if (presetColors && presetColors[index]) {
        const presetColorData = presetColors[index];
        applySettings({
          ...preset,
          baseColors: presetColorData.baseColors,
          displayColors: presetColorData.displayColors,
          seed: presetColorData.seed,
        });
        
        // Reset color type to default
        setColorType('default');
        
        // Reset unique colors
        setUniqueColors(Array.from(new Set(presetColorData.displayColors)));
      } else {
        console.error('Preset colors not available');
        // Fallback to generating new colors for the preset
        const { baseColors, displayColors } = generateColors(preset.rows, preset.columns, preset.paletteStyle, preset.pattern);
        applySettings({
          ...preset,
          baseColors,
          displayColors,
        });
        
        // Reset color type to default
        setColorType('default');
        
        // Reset unique colors
        setUniqueColors(Array.from(new Set(displayColors)));
      }
    }
  }, [currentPresetIndex, previousSettings, applySettings, generateColors, presetColors, presets]);

  const handleSettingChange = (setting, value) => {
    if (currentPresetIndex !== null) {
      setIsPresetModified(true);
    }
    if (currentPresetIndex !== null && isEditingPreset) {
      const updatedPreset = { ...presets[currentPresetIndex], [setting]: value };
      const { baseColors: newBaseColors, displayColors: newDisplayColors } = generateColors(updatedPreset.rows, updatedPreset.columns, updatedPreset.paletteStyle, updatedPreset.pattern);
      const newSettings = {
        ...updatedPreset,
        baseColors: newBaseColors,
        displayColors: newDisplayColors
      };
      applySettings(newSettings);
    }
  };

  const handlePaletteStyleChange = (newPaletteStyle) => {
    if (currentPresetIndex !== null) {
      setIsPresetModified(true);
    }
    console.log('Changing palette style to:', newPaletteStyle);
    setPaletteStyle(newPaletteStyle);
    setColorType('default');
    
    let newBaseColors;
    if (colorType === 'custom') {
      newBaseColors = baseColors;
    } else {
      const { baseColors: generatedBaseColors } = generateColors(rows, columns, newPaletteStyle, pattern);
      newBaseColors = generatedBaseColors;
    }
    
    setBaseColors(newBaseColors);
    const newDisplayColors = patterns[pattern](newBaseColors, columns, rows);
    setDisplayColors(newDisplayColors);
    renderColors(newDisplayColors, rows, columns);
  };

  const handlePatternChange = (newPattern) => {
    if (currentPresetIndex !== null) {
      setIsPresetModified(true);
    }
    console.log('Changing pattern to:', newPattern);
    setPattern(newPattern);
    const { baseColors: newBaseColors, displayColors: newDisplayColors } = generateColors(rows, columns, paletteStyle, newPattern);
    setBaseColors(newBaseColors);
    setDisplayColors(newDisplayColors);
    renderColors(newDisplayColors, rows, columns);
  };

  const TagIndicator = ({ isModified }) => {
    return (
      <AnimatePresence>
        {isModified && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
            className="absolute top-2 left-2 bg-pink-500 text-white text-xs font-semibold px-2 py-1 rounded-full shadow-sm z-10"
          >
            Modified
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  const AspectRatioIcon = ({ width, height, name, small = false, showText = true }) => {
    const containerSize = small ? 28 : 48;
    const aspectRatio = width / height;
    let iconWidth, iconHeight;

    if (aspectRatio > 1) {
      iconWidth = containerSize;
      iconHeight = containerSize / aspectRatio;
    } else {
      iconHeight = containerSize;
      iconWidth = containerSize * aspectRatio;
    }
    
    return (
      <div className="flex items-center justify-center">
        <div
          className="border border-gray-300 rounded-[4px] flex items-center justify-center bg-transparent"
          style={{
            width: `${iconWidth}px`,
            height: `${iconHeight}px`,
          }}
        >
          {showText && <span className="text-xs font-medium text-gray-600">{name}</span>}
        </div>
      </div>
    );
  };

  const renderPresetMobile = useCallback((preset, index) => {
    const presetColorData = presetColors[index];
    const displayColors = presetColorData ? presetColorData.displayColors : [];
    const isSelected = currentPresetIndex === index;

    return (
      <motion.div 
        className={`
          rounded-lg overflow-hidden relative cursor-pointer
          ${isSelected ? 'ring-2 ring-pink-500' : 'ring-1 ring-gray-200'}
          hover:shadow-md focus:outline-none
        `}
        onClick={() => handlePresetClick(index)}
        style={{ paddingBottom: '80%' }}
        initial={false}
        animate={{
          scale: isSelected ? 1.00 : 1,
          transition: { duration: 0.2, ease: "easeInOut" }
        }}
        whileHover="hover"
      >
        {displayColors.length > 0 ? (
          <div 
            className="absolute inset-0"
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${preset.columns}, 1fr)`,
              gridTemplateRows: `repeat(${preset.rows}, 1fr)`,
            }}
          >
            {displayColors.map((color, i) => (
              <div 
                key={i} 
                style={{
                  backgroundColor: color,
                }}
              />
            ))}
          </div>
        ) : (
          <div className="absolute inset-0 bg-gray-200 flex items-center justify-center">
            <span className="text-gray-500">Loading...</span>
          </div>
        )}
        <AnimatePresence>
          {isSelected && (
            <motion.div 
              className="absolute top-2 right-2 bg-pink-500 rounded-full p-1"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <Check className="h-4 w-4 text-white" />
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          className="absolute inset-0 bg-black pointer-events-none"
          initial={{ opacity: isSelected ? 0 : 0.25 }}
          animate={{ opacity: isSelected ? 0 : 0.25 }}
          variants={{
            hover: { opacity: 0 }
          }}
          transition={{ duration: 0.3 }}
        />
      </motion.div>
    );
  }, [currentPresetIndex, handlePresetClick, presetColors]);

  const renderPresetDesktop = useCallback((preset, index) => {
    const presetColorData = presetColors[index];
    const displayColors = presetColorData ? presetColorData.displayColors : [];
    const isSelected = currentPresetIndex === index;

  return (
      <motion.div 
        className={`
          rounded-lg overflow-hidden relative cursor-pointer
          ${isSelected ? 'ring-2 ring-pink-500' : 'ring-1 ring-gray-200'}
          transition-all duration-200 ease-in-out
          hover:shadow-md focus:outline-none
        `}
        onClick={() => handlePresetClick(index)}
        style={{ paddingBottom: '80%' }}
        whileHover="hover"
      >
        {displayColors.length > 0 ? (
          <div 
            className="absolute inset-0"
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${preset.columns}, 1fr)`,
              gridTemplateRows: `repeat(${preset.rows}, 1fr)`,
            }}
          >
            {displayColors.map((color, i) => (
              <div 
                key={i} 
                style={{
                  backgroundColor: color,
                }}
              />
            ))}
                    </div>
        ) : (
          <div className="absolute inset-0 bg-gray-200 flex items-center justify-center">
            <span className="text-gray-500">Loading...</span>
                    </div>
        )}
        {isSelected && (
          <div className="absolute top-2 right-2 bg-pink-500 rounded-full p-1">
            <Check className="h-4 w-4 text-white" />
                  </div>
        )}
        <motion.div
          className="absolute inset-0 bg-black pointer-events-none"
          initial={{ opacity: isSelected ? 0 : 0.25 }}
          animate={{ opacity: isSelected ? 0 : 0.25 }}
          variants={{
            hover: { opacity: 0 }
          }}
          transition={{ duration: 0.3 }}
        />
      </motion.div>
    );
  }, [currentPresetIndex, handlePresetClick, presetColors]);

  const renderPreset = (preset, index) => {
    const isSelected = currentPresetIndex === index;
    const isModified = isSelected && isPresetModified;
    
    const presetColorData = presetColors[index];
    const displayColors = presetColorData ? presetColorData.displayColors : [];
    
    return (
      <div className="mb-6">
        <motion.div 
          className={`
            cursor-pointer
            rounded-lg overflow-hidden
            relative
            group
            ${isSelected ? 'ring-2 ring-pink-500' : 'ring-1 ring-gray-200'}
            ${isSelected ? '' : 'brightness-75 hover:brightness-90'}
            hover:shadow-md
          `}
          onClick={() => handlePresetClick(index)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
          style={{
            paddingTop: '75%', // 4:3 aspect ratio
          }}
        >
          <TagIndicator isModified={isModified} />
          {displayColors.length > 0 ? (
            <div 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'grid',
                gridTemplateColumns: `repeat(${preset.columns}, 1fr)`,
                gridTemplateRows: `repeat(${preset.rows}, 1fr)`,
              }}
            >
              {displayColors.map((color, i) => (
                <div 
                  key={i} 
                  style={{
                    backgroundColor: color,
                  }}
                />
              ))}
                      </div>
          ) : (
            <div className="absolute inset-0 bg-gray-200 flex items-center justify-center">
              <span className="text-gray-500">Loading...</span>
                      </div>
                    )}
          <AnimatePresence>
            {isSelected && (
              <motion.div 
                className="absolute top-2 right-2 bg-pink-500 rounded-full p-1"
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{
                  duration: 0.2,
                  ease: [0.25, 0.1, 0.25, 1]
                }}
              >
                <Check className="h-4 w-4 text-white" />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
                    </div>
    );
  };

  const updatePreviewScale = useCallback(() => {
    const scaleX = previewWidth / outputResolution.width;
    const scaleY = previewHeight / outputResolution.height;
    setPreviewScale(Math.min(scaleX, scaleY));
  }, [outputResolution.width, outputResolution.height]);

  useEffect(() => {
    updatePreviewScale();
  }, [updatePreviewScale, outputResolution.width, outputResolution.height]);

  const updateGridSize = useCallback((width, height) => {
    const aspectRatio = width / height;
    let newColumns = columns;
    let newRows = Math.round(newColumns / aspectRatio);

    if (newRows * newColumns > MAX_CELLS) {
      const scale = Math.sqrt(MAX_CELLS / (newRows * newColumns));
      newColumns = Math.floor(newColumns * scale);
      newRows = Math.floor(newRows * scale);
    }

    setColumns(newColumns);
    setRows(newRows);
    
    const { baseColors: newBaseColors, displayColors: newDisplayColors } = generateColors(newRows, newColumns, paletteStyle, pattern);
    setBaseColors(newBaseColors);
    setDisplayColors(newDisplayColors);
  }, [columns, paletteStyle, pattern, generateColors]);

  const handleAspectRatioChange = (newRatio) => {
    setAspectRatio(newRatio);
    const selectedRatio = aspectRatios.find(ratio => ratio.name === newRatio);
    if (selectedRatio) {
      const newWidth = selectedRatio.width;
      const newHeight = selectedRatio.height;
      setBaseResolution({ width: newWidth, height: newHeight });
      setOutputResolution({ width: newWidth, height: newHeight });
      setCustomWidth(newWidth);
      setCustomHeight(newHeight);
      
      // Reset image quality to '1x'
      setImageQuality('1x');
    }
  };

  useEffect(() => {
    if (!isComponentMounted) return;
    
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.width = outputResolution.width;
    canvas.height = outputResolution.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const cellWidth = outputResolution.width / columns;
    const cellHeight = outputResolution.height / rows;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    displayColors.forEach((color, index) => {
      const x = (index % columns) * cellWidth;
      const y = Math.floor(index / columns) * cellHeight;
      ctx.fillStyle = color;
      ctx.fillRect(x, y, cellWidth, cellHeight);
    });
  }, [isComponentMounted, displayColors, columns, rows, outputResolution.width, outputResolution.height]);

  const generateSeed = () => {
    const settings = {
                            rows,
                            columns,
                            paletteStyle,
      pattern,
      baseColors
    };
    return btoa(JSON.stringify(settings));
  };

  const applySeed = (seed) => {
    try {
      const settings = JSON.parse(atob(seed));
      setRows(settings.rows);
      setColumns(settings.columns);
      setPaletteStyle(settings.paletteStyle);
      setPattern(settings.pattern);
      const hashSeed = hashString(`${settings.rows}-${settings.columns}-${settings.paletteStyle}-${settings.pattern}`);
      const { baseColors, displayColors } = generateColors(settings.rows, settings.columns, settings.paletteStyle, settings.pattern, null, hashSeed);
                          setBaseColors(baseColors);
                          setDisplayColors(displayColors);
      setCurrentSeed(seed);
    } catch (error) {
      console.error('Invalid seed:', error);
      setError('Invalid seed. Please try again.');
    }
  };

  const Template = ({ children }) => (
    <div className="p-4 border rounded-md hover-animate">
      {children}
                    </div>
  )

  const handleGridSizeChange = useCallback((newRows, newColumns) => {
    setInputRows(newRows);
    setInputColumns(newColumns);
    
    const totalCells = newRows * newColumns;
    
    if (totalCells > MAX_CELLS) {
      setCellLimitReached(true);
    } else {
      setCellLimitReached(false);
      setRows(newRows);
      setColumns(newColumns);

      const { baseColors: newBaseColors, displayColors: newDisplayColors } = generateColors(newRows, newColumns, paletteStyle, pattern);
      setBaseColors(newBaseColors);
      setDisplayColors(newDisplayColors);
    }

    if (isPresetSelected) {
      setIsPresetModified(true);
    }
  }, [generateColors, isPresetSelected, paletteStyle, pattern]);

  const resetToPresetDefault = useCallback(() => {
    if (currentPresetIndex !== null) {
      const preset = presets[currentPresetIndex];
      handleGridSizeChange(preset.rows, preset.columns);
      setIsPresetModified(false);
    }
  }, [currentPresetIndex, handleGridSizeChange]);

  const handleInputChange = (value, isRows) => {
    // Only allow numeric input
    const numericValue = value.replace(/[^0-9]/g, '');
    
    // Remove leading zeros and convert to number
    let cleanedValue = numericValue === '' ? 1 : parseInt(numericValue, 10) || 1;
    
    // Ensure the value is at least 1
    cleanedValue = Math.max(1, cleanedValue);

    if (isRows) {
      setInputRows(cleanedValue);
      handleGridSizeChange(cleanedValue, inputColumns);
    } else {
      setInputColumns(cleanedValue);
      handleGridSizeChange(inputRows, cleanedValue);
    }
  };

  const handleDownloadOptionChange = (value) => {
    setCurrentDownloadOption(value);
  };

  const customButtonClass = cn(
    "bg-black bg-opacity-95 text-white",
    "transition-colors duration-300 ease-in-out",
    "hover:bg-opacity-100"
  );

  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const handleExport = () => {
    setLoadingMessage("Generating image...");
    
    setTimeout(() => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      if (!ctx) {
        console.error('Failed to get 2D context');
        setLoadingMessage(null);
        return;
      }
      
      let scaleFactor = 1;
      switch (imageQuality) {
        case '2x': scaleFactor = 2; break;
        case '3x': scaleFactor = 3; break;
        case '4x': scaleFactor = 4; break;
        default: scaleFactor = 1;
      }
      
      let targetWidth = outputResolution.width * scaleFactor;
      let targetHeight = outputResolution.height * scaleFactor;

      // Check if dimensions exceed the maximum allowed size
      if (targetWidth > MAX_CANVAS_DIMENSION || targetHeight > MAX_CANVAS_DIMENSION) {
        const aspectRatio = targetWidth / targetHeight;
        if (targetWidth > targetHeight) {
          targetWidth = MAX_CANVAS_DIMENSION;
          targetHeight = Math.round(targetWidth / aspectRatio);
        } else {
          targetHeight = MAX_CANVAS_DIMENSION;
          targetWidth = Math.round(targetHeight * aspectRatio);
        }
      }

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      console.log('Canvas dimensions:', canvas.width, 'x', canvas.height);

      const cellWidth = canvas.width / columns;
      const cellHeight = canvas.height / rows;

      displayColors.forEach((color, index) => {
        const x = (index % columns) * cellWidth;
        const y = Math.floor(index / columns) * cellHeight;
        ctx.fillStyle = color;
        ctx.fillRect(x, y, cellWidth, cellHeight);
      });

      canvas.toBlob((blob) => {
        if (blob) {
          try {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = `color-grid-${canvas.width}x${canvas.height}.${imageFormat}`;
            link.href = url;
            link.click();
            URL.revokeObjectURL(url);

            setLoadingMessage(null);
            setDownloadSuccess(true);
            setSuccessMessage("Image downloaded successfully!");
            setTimeout(() => {
              setDownloadSuccess(false);
              setSuccessMessage(null);
            }, 3000);
          } catch (error) {
            console.error('Error creating object URL:', error);
            setLoadingMessage(null);
            alert('Failed to create download link. Please try again.');
          }
        } else {
          console.error('Failed to create blob');
          setLoadingMessage(null);
          alert('Failed to generate image. Please try again.');
        }
      }, `image/${imageFormat}`, 1.0);
    }, 100);
  };

  const handleCopyImageToClipboard = async () => {
    setLoadingMessage("Preparing image...");
    
    try {
      const canvas = document.createElement('canvas');
      
      let scaleFactor = 1;
      switch (imageQuality) {
        case '2x': scaleFactor = 2; break;
        case '3x': scaleFactor = 3; break;
        case '4x': scaleFactor = 4; break;
        default: scaleFactor = 1;
      }

      let targetWidth = outputResolution.width * scaleFactor;
      let targetHeight = outputResolution.height * scaleFactor;

      if (targetWidth > MAX_CANVAS_DIMENSION || targetHeight > MAX_CANVAS_DIMENSION) {
        const aspectRatio = targetWidth / targetHeight;
        if (targetWidth > targetHeight) {
          targetWidth = MAX_CANVAS_DIMENSION;
          targetHeight = Math.round(targetWidth / aspectRatio);
        } else {
          targetHeight = MAX_CANVAS_DIMENSION;
          targetWidth = Math.round(targetHeight * aspectRatio);
        }
      }

      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext('2d');
      
      displayColors.forEach((color, index) => {
        const x = (index % columns) * (canvas.width / columns);
        const y = Math.floor(index / columns) * (canvas.height / rows);
        ctx.fillStyle = color;
        ctx.fillRect(x, y, canvas.width / columns, canvas.height / rows);
      });

      // Try to copy to clipboard
      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            await navigator.clipboard.write([
              new ClipboardItem({
                [blob.type]: blob
              })
            ]);
            setLoadingMessage(null);
            setCopySuccess(true);
            setSuccessMessage("Image copied to clipboard!");
          } catch (err) {
            console.error("Failed to copy image to clipboard:", err);
            // Fall back to download
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `color-grid-${canvas.width}x${canvas.height}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setLoadingMessage(null);
            setCopySuccess(true);
            setSuccessMessage("Image downloaded. Please copy manually.");
          }
        } else {
          throw new Error('Failed to create blob from canvas');
        }
      }, 'image/png');
    } catch (error) {
      console.error('Failed to prepare image:', error);
      setLoadingMessage(null);
      alert('Failed to prepare image. Please try again.');
    }

    setTimeout(() => {
      setCopySuccess(false);
      setSuccessMessage(null);
    }, 3000);
  };

  const getSelectedAspectRatioDisplay = () => {
    const selectedRatio = aspectRatios.find(ratio => ratio.name === aspectRatio);
    if (selectedRatio) {
      return {
        name: selectedRatio.name,
        dimensions: `${outputResolution.width} x ${outputResolution.height}`,
        width: outputResolution.width,
        height: outputResolution.height
      };
    }
    return {
      name: 'Custom',
      dimensions: `${outputResolution.width} x ${outputResolution.height}`,
      width: outputResolution.width,
      height: outputResolution.height
    };
  };

  const getRandomGridSize = () => {
    const maxSize = Math.floor(Math.sqrt(MAX_CELLS));
    return {
      rows: Math.floor(Math.random() * maxSize) + 1,
      columns: Math.floor(Math.random() * maxSize) + 1
    };
  };

  const getRandomPaletteStyle = () => {
    const styles = Object.keys(paletteStyleDisplayNames);
    return styles[Math.floor(Math.random() * styles.length)];
  };

  const getRandomPattern = () => {
    const patterns = Object.keys(patternDisplayNames);
    return patterns[Math.floor(Math.random() * patterns.length)];
  };

  const fitToMaxCells = () => {
    const currentAspectRatio = inputColumns / inputRows;
    let newRows = inputRows;
    let newColumns = inputColumns;

    // If current total is less than MAX_CELLS, no need to adjust
    if (newRows * newColumns <= MAX_CELLS) {
      return;
    }

    // Reduce the larger dimension first to maintain aspect ratio as much as possible
    if (newColumns > newRows) {
      newColumns = Math.floor(MAX_CELLS / newRows);
      if (newColumns * newRows > MAX_CELLS) {
        newColumns--;
      }
    } else {
      newRows = Math.floor(MAX_CELLS / newColumns);
      if (newRows * newColumns > MAX_CELLS) {
        newRows--;
      }
    }

    // Fine-tune to get closer to MAX_CELLS if possible
    while ((newRows + 1) * newColumns <= MAX_CELLS) {
      newRows++;
    }
    while (newRows * (newColumns + 1) <= MAX_CELLS) {
      newColumns++;
    }

    setInputRows(newRows);
    setInputColumns(newColumns);
    handleGridSizeChange(newRows, newColumns);
  };

  console.log('Palette Style Definitions:', paletteStyleDefinitions);
  console.log('Pattern Definitions:', patternDefinitions);
  console.log('Palette Style Display Names:', paletteStyleDisplayNames);
  console.log('Pattern Display Names:', patternDisplayNames);

  const randomizeAll = () => {
    // Generate random palette style (excluding 'earth' for more vibrant results)
    const paletteStyles = ['monochromatic', 'harmonious', 'pastel', 'vibrant', 'analogous', 'complementary', 'triadic'];
    const newPaletteStyle = paletteStyles[Math.floor(Math.random() * paletteStyles.length)];

    // Generate random pattern
    const patterns = ['linear', 'diagonal', 'spiral', 'random', 'zigZag'];
    const newPattern = patterns[Math.floor(Math.random() * patterns.length)];

    // Generate random grid size
    const minSize = 2;
    const maxSize = 20;
    const newRows = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
    const newColumns = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;

    // Apply new settings
    setPaletteStyle(newPaletteStyle);
    setPattern(newPattern);
    setRows(newRows);
    setColumns(newColumns);

    // Generate new colors
    const { baseColors: newBaseColors, displayColors: newDisplayColors } = generateColors(newRows, newColumns, newPaletteStyle, newPattern);
    setBaseColors(newBaseColors);
    setDisplayColors(newDisplayColors);

    // Reset color type to default
    setColorType('default');

    // Deselect any preset
    setCurrentPresetIndex(null);
    setIsEditingPreset(false);
    setIsPresetModified(false);
    setIsPresetSelected(false);
  };

  const handleIframeClick = (event) => {
    event.preventDefault();
    window.open('https://creatorstoolbox.co/subscribe', '_blank');
  };

  const [popoverWidth, setPopoverWidth] = useState(0);
  const buttonGroupRef = useRef(null);

  useEffect(() => {
    if (!buttonGroupRef.current) return;

    const updatePopoverWidth = () => {
      if (buttonGroupRef.current) {
        setPopoverWidth(buttonGroupRef.current.offsetWidth);
      }
    };

    // Call once to set initial width
    updatePopoverWidth();

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver(updatePopoverWidth);
    resizeObserver.observe(buttonGroupRef.current);

    // Cleanup
    return () => {
      if (buttonGroupRef.current) {
        resizeObserver.unobserve(buttonGroupRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isComponentMounted) {
      const initialColors = generateColors(DEFAULT_ROWS, DEFAULT_COLUMNS, paletteStyle, pattern);
      setBaseColors(initialColors.baseColors);
      setDisplayColors(initialColors.displayColors);
      setIsComponentMounted(true);
    }
  }, [isComponentMounted, generateColors, paletteStyle, pattern]);

  const SettingsSection = ({ title, children, info }) => (
    <div className="space-y-2 mb-6">
      <div className="flex items-center justify-between mb-2">
        <Label className="text-lg font-semibold text-gray-900">{title}</Label>
        {info && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Info className="h-4 w-4 text-gray-400" />
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-sm text-gray-600">{info}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
                  </div>
      <div className="border border-gray-200 rounded-md p-4">
        {children}
                    </div>
                    </div>
  );

  const getSelectedResolution = () => {
    const baseWidth = outputResolution.width;
    const baseHeight = outputResolution.height;
    const multiplier = imageQuality === 'original' ? 1 : parseInt(imageQuality);
    
    return `${baseWidth * multiplier} x ${baseHeight * multiplier}`;
  };

  const getDownloadButtonText = () => {
    const quality = imageQuality === 'original' ? '1X' : imageQuality.toUpperCase();
    return `Download as ${imageFormat.toUpperCase()} (${quality})`;
  };

  const hiddenCanvasRef = useRef(null);

  const createHiddenCanvas = () => {
    const canvas = document.createElement('canvas');
    canvas.width = outputResolution.width;
    canvas.height = outputResolution.height;
    const ctx = canvas.getContext('2d');
    
    // Draw the color grid on this canvas
    displayColors.forEach((color, index) => {
      const x = (index % columns) * (canvas.width / columns);
      const y = Math.floor(index / columns) * (canvas.height / rows);
      ctx.fillStyle = color;
      ctx.fillRect(x, y, canvas.width / columns, canvas.height / rows);
    });

    return canvas;
  };

  const getCurrentSettings = () => {
    return {
      resolution: `${outputResolution.width * (imageQuality === 'original' ? 1 : parseInt(imageQuality))} x ${outputResolution.height * (imageQuality === 'original' ? 1 : parseInt(imageQuality))}`,
      format: imageFormat.toUpperCase(),
      quality: imageQuality,
      rows,
      columns,
      paletteStyle: paletteStyleDisplayNames[paletteStyle],
      pattern: patternDisplayNames[pattern],
    };
  };

  // Add these constants at the top of your component
  const PREVIEW_CONTAINER_WIDTH = 810;
  const PREVIEW_CONTAINER_HEIGHT = 598;
  const PREVIEW_PADDING = 20;

  // Update the previewScale calculation in your useEffect or wherever it's defined
  useEffect(() => {
    const scaleX = (PREVIEW_CONTAINER_WIDTH - 2 * PREVIEW_PADDING) / outputResolution.width;
    const scaleY = (PREVIEW_CONTAINER_HEIGHT - 2 * PREVIEW_PADDING) / outputResolution.height;
    setPreviewScale(Math.min(scaleX, scaleY));
  }, [outputResolution.width, outputResolution.height]);

  const calculateScale = () => {
    const containerWidth = 810; // Adjust this based on your actual container width
    const containerHeight = 598; // Adjust this based on your actual container height
    const containerAspectRatio = containerWidth / containerHeight;
    const gridAspectRatio = outputResolution.width / outputResolution.height;
    
    if (gridAspectRatio > containerAspectRatio) {
      return (containerWidth / outputResolution.width) * 0.9; // 90% of container width
    } else {
      return (containerHeight / outputResolution.height) * 0.9; // 90% of container height
    }
  };

  // Confirm all options are here
  console.log('Palette Style Options:', Object.keys(paletteStyleDisplayNames));

  const selectContentStyle = {
    width: 'var(--radix-select-trigger-width)',
    maxHeight: '300px', // Adjust this value as needed
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent'
  };

  const [customSize, setCustomSize] = useState({ width: outputResolution.width, height: outputResolution.height });
  const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);

  // Update this function to calculate the correct dimensions for the preview
  const calculatePreviewDimensions = () => {
    const containerWidth = PREVIEW_CONTAINER_WIDTH;
    const containerHeight = PREVIEW_CONTAINER_HEIGHT;
    const gridAspectRatio = outputResolution.width / outputResolution.height;
    
    let previewWidth = containerWidth;
    let previewHeight = containerWidth / gridAspectRatio;

    if (previewHeight > containerHeight) {
      previewHeight = containerHeight;
      previewWidth = containerHeight * gridAspectRatio;
    }
    
    return { width: previewWidth, height: previewHeight };
  };

  // Update the preview dimensions when the output resolution changes
  useEffect(() => {
    const { width, height } = calculatePreviewDimensions();
    setPreviewScale(Math.min(width / outputResolution.width, height / outputResolution.height));
  }, [outputResolution.width, outputResolution.height]);

  const containerRef = useRef(null);
  const [previewSize, setPreviewSize] = useState(0);

  useEffect(() => {
    const updatePreviewSize = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const minDimension = Math.min(containerRect.width, containerRect.height);
        setPreviewSize(minDimension * 0.9); // 90% of the smaller dimension
      }
    };

    updatePreviewSize();
    window.addEventListener('resize', updatePreviewSize);
    return () => window.removeEventListener('resize', updatePreviewSize);
  }, []);

  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    console.log('Updating colors');
    console.log('Rows:', rows);
    console.log('Columns:', columns);
    console.log('Display colors:', displayColors);
    // Your existing code to update colors
  }, [rows, columns, paletteStyle, pattern]);

  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (copySuccess) {
      const timer = setTimeout(() => {
        setCopySuccess(false);
      }, 3000); // Hide after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [copySuccess]);

  // Add this new state to manage which sections are expanded
  const [expandedSections, setExpandedSections] = useState({
    gridSize: !isMobile,
    color: !isMobile,
    pattern: !isMobile,
    export: !isMobile,
    aspectRatio: false,
  });

  // Add this function to toggle section expansion
  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <TooltipProvider>
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Header 
          title="Color Palette Grid Generator" 
          subtitle="Create beautiful color grid patterns for your designs"
        />
        
        <EmailGatePopupWrapper 
          show={showEmailGate} 
          onClose={() => setShowEmailGate(false)} 
          showCloseButton={showCloseButton}
          toolName="Color Palette Grid Generator"
          iframeSrc="https://embeds.beehiiv.com/6c921196-fe1c-46eb-9b87-d02638045448?slim=true"
        />

        {/* Main content */}
        <main className="flex-grow flex flex-col lg:flex-row items-start justify-center py-5">
          <div className="container mx-auto px-4">
            <div className="w-full h-auto sm:h-auto lg:h-[calc(100vh-100px)] flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
              {/* Left sidebar */}
              <div className="w-full lg:w-1/4 bg-white rounded-lg shadow-sm overflow-hidden flex flex-col order-2 lg:order-1">
                <div className="flex-grow overflow-auto">
                  <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    renderThumbVertical={({ style, ...props }) =>
                      <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                    }
                    style={{ height: 'calc(100vh - 220px)' }}
                  >
                    <div className="p-4">
                      <h2 className="text-2xl font-semibold text-gray-900 mb-6">Settings</h2>
                      <div className="space-y-4">
                    {/* Aspect Ratio Dropdown */}
                    <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                          <div 
                            className="flex justify-between items-center p-4 cursor-pointer bg-white"
                            onClick={() => toggleSection('aspectRatio')}
                          >
                            {(() => {
                              const { name, dimensions, width, height } = getSelectedAspectRatioDisplay();
                              return (
                                <div className="flex items-center">
                                  <AspectRatioIcon width={width} height={height} name={name} small={true} showText={false} />
                                  <div className="flex items-baseline ml-3">
                                    <span className="text-lg font-semibold text-gray-900">{name}</span>
                                    <span className="text-lg text-gray-500 ml-2">({dimensions})</span>
                    </div>
                  </div>
                              );
                            })()}
                            <AnimatedChevron isOpen={expandedSections.aspectRatio} />
                    </div>
                          {expandedSections.aspectRatio && (
                            <div className="px-4 pb-4">
                              <div className="space-y-4">
                                <div>
                                  <div className="flex justify-between items-center mb-2">
                                    <h3 className="font-semibold text-sm">Aspect Ratio</h3>
                                    <span className="text-sm text-gray-500">
                                      {outputResolution.width} x {outputResolution.height}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                                    {aspectRatios.map((ratio) => (
                                      <motion.button
                                        key={ratio.name}
                                        onClick={() => handleAspectRatioChange(ratio.name)}
                                        className={cn(
                                          "h-16 sm:h-20 p-1 rounded-lg border",
                                          aspectRatio === ratio.name ? "border-pink-500 bg-pink-50" : "border-gray-200"
                                        )}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        transition={{ duration: 0.2 }}
                                      >
                                        <div className="flex flex-col items-center justify-center h-full w-full">
                                          <AspectRatioIcon width={ratio.width} height={ratio.height} name={ratio.name} showText={true} />
                    </div>
                                      </motion.button>
                                    ))}
                  </div>
                    </div>
                                <div>
                                  <h3 className="font-semibold text-sm mb-2">Custom Size</h3>
                                  <div className="flex items-center space-x-2">
                                    <input
                                      type="number"
                                      value={customSize.width}
                                      onChange={(e) => setCustomSize({ ...customSize, width: Number(e.target.value) })}
                                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none text-gray-500"
                                      style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                                    />
                                    <span className="flex-shrink-0 text-gray-500">×</span>
                                    <input
                                      type="number"
                                      value={customSize.height}
                                      onChange={(e) => setCustomSize({ ...customSize, height: Number(e.target.value) })}
                                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none text-gray-500"
                                      style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
                                    />
                      <Button
                        onClick={() => {
                                        setAspectRatio('Custom');
                                        setOutputResolution(customSize);
                                        updateGridSize(customSize.width, customSize.height);
                                      }}
                                      className="bg-black text-white hover:bg-gray-800 flex-shrink-0 px-3 py-2"
                                    >
                                      Set
                      </Button>
                    </div>
                  </div>
                    </div>
                    </div>
                          )}
                  </div>

                        {/* Existing settings */}
                        <div className="space-y-4">
                          {/* Grid Size */}
                          <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                            <div 
                              className="flex justify-between items-center p-4 cursor-pointer bg-white"
                              onClick={() => toggleSection('gridSize')}
                            >
                              <h3 className="text-lg font-semibold">Grid Size</h3>
                              <AnimatedChevron isOpen={expandedSections.gridSize} />
                    </div>
                            {expandedSections.gridSize && (
                              <div className="px-4 pb-4">
                                <div className="flex items-end space-x-2">
                    <div className="flex-1">
                                    <label htmlFor="rows" className="block text-sm font-medium text-gray-700 mb-1">Rows</label>
                                    <input
                                      type="text" 
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      id="rows"
                                      value={inputRows || ''}
                                      onChange={(e) => handleInputChange(e.target.value, true)}
                                      onBlur={() => {
                                        if (!inputRows) setInputRows(1);
                                      }}
                                      className="w-full rounded-md border border-gray-300 shadow-sm h-10 px-3 focus:outline-none focus:ring-0 focus:border-gray-300"
                                    />
                    </div>
                    <div className="flex-1">
                                    <label htmlFor="columns" className="block text-sm font-medium text-gray-700 mb-1">Columns</label>
                                    <input
                                      type="text" 
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      id="columns"
                                      value={inputColumns || ''}
                                      onChange={(e) => handleInputChange(e.target.value, false)}
                                      onBlur={() => {
                                        if (!inputColumns) setInputColumns(1);
                                      }}
                                      className="w-full rounded-md border border-gray-300 shadow-sm h-10 px-3 focus:outline-none focus:ring-0 focus:border-gray-300"
                                    />
                    </div>
                      <Button
                        onClick={() => {
                                      const { rows: newRows, columns: newColumns } = getRandomGridSize();
                                      handleGridSizeChange(newRows, newColumns);
                        }}
                                    className="h-10 w-10 px-0 bg-gray-100 hover:bg-gray-200 text-gray-600"
                      >
                                    <Shuffle className="h-4 w-4" />
                      </Button>
                    </div>
                                <div className="flex justify-between items-center mt-2">
                                  <p className="text-sm text-gray-500">Total Cells: {inputRows * inputColumns} / 360</p>
                                  {isPresetSelected && isPresetModified && (
                                    <button
                                      onClick={resetToPresetDefault}
                                      className="text-sm text-pink-600 hover:text-pink-800 font-medium flex items-center"
                                    >
                                      Reset
                                      <RotateCcw className="h-4 w-4 ml-1" />
                                    </button>
                                  )}
                    </div>
                                {/* Add this new block for the cell limit message */}
                                <AnimatePresence>
                                  {cellLimitReached && (
                                    <motion.div
                                      initial={{ opacity: 0, y: -10 }}
                                      animate={{ opacity: 1, y: 0 }}
                                      exit={{ opacity: 0, y: -10 }}
                                      transition={{ duration: 0.3 }}
                                      className="mt-2 p-3 bg-gray-100 border border-gray-200 text-gray-700 rounded-md relative"
                                    >
                                      <p className="text-sm pr-6">
                                        You've reached the maximum of 360 cells. Please reduce the number of rows or columns to add more.{' '}
                                        <button
                                          onClick={fitToMaxCells}
                                          className="text-pink-600 hover:text-pink-800 font-medium inline-flex items-center"
                                        >
                                          Fit to max <Maximize2 className="ml-1 h-3 w-3" />
                                        </button>
                                      </p>
                                      <button
                                        onClick={() => setCellLimitReached(false)}
                                        className="absolute top-1 right-1 p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
                                      >
                                        <X className="h-4 w-4 text-gray-500" />
                                      </button>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                    </div>
                            )}
                  </div>

                          {/* Style */}
                          <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                            <div 
                              className="flex justify-between items-center p-4 cursor-pointer bg-white"
                              onClick={() => toggleSection('color')}
                            >
                              <h3 className="text-lg font-semibold">Color</h3>
                              <AnimatedChevron isOpen={expandedSections.color} />
                    </div>
                            {expandedSections.color && (
                              <div className="px-4 pb-4 bg-white space-y-4">
                                {/* Style Dropdown */}
                                <div>
                                  <label className="block text-sm font-medium text-gray-700 mb-1">Style</label>
                                  <div className="flex items-center space-x-2">
                                    <Select 
                                      value={paletteStyle}
                                      onValueChange={(value) => handlePaletteStyleChange(value)}
                                      disabled={isPresetSelected}
                                    >
                                      <SelectTrigger className="w-full">
                                        <SelectValue>{paletteStyleDisplayNames[paletteStyle]}</SelectValue>
                                      </SelectTrigger>
                                      <SelectContent className="bg-white border border-gray-200 shadow-lg rounded-md overflow-hidden">
                                        <Scrollbars 
                                          style={{ height: 300 }} 
                                          autoHide
                                          autoHideTimeout={750}
                                          autoHideDuration={300}
                                          renderThumbVertical={({ style, ...props }) =>
                                            <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                                          }
                                        >
                                          <div className="py-1">
                                            {Object.entries(paletteStyleDisplayNames).map(([value, displayName]) => (
                                              <SelectItem 
                                                key={value} 
                                                value={value}
                                                className={cn(
                                                  "py-2 px-4 cursor-pointer transition-colors duration-150 ease-in-out relative",
                                                  "hover:bg-gray-100",
                                                  paletteStyle === value && "bg-gray-100"
                                                )}
                                              >
                                                <div className="pr-8">
                                                  <span className="font-medium text-gray-800 block">{displayName}</span>
                                                  <span className="text-sm text-gray-500 block">{paletteStyleDefinitions[value]}</span>
                    </div>
                                                {paletteStyle === value && (
                                                  <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                                                    <Check className="h-4 w-4 text-pink-500" />
                  </div>
                                                )}
                                              </SelectItem>
                                            ))}
                    </div>
                                        </Scrollbars>
                                      </SelectContent>
                                    </Select>
                      <Button
                        onClick={() => {
                                        const newStyle = getRandomPaletteStyle();
                                        handlePaletteStyleChange(newStyle);
                        }}
                                      className="h-10 px-3 bg-gray-100 hover:bg-gray-200 text-gray-600"
                                      disabled={isPresetSelected}
                      >
                                      <Shuffle className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>

                                {/* Base Colors */}
                                <div>
                                  <div className="flex justify-between items-center mb-1">
                                    <label className="block text-sm font-medium text-gray-700">Base Colors</label>
                                    <button
                                      onClick={() => handleColorTypeChange(colorType === 'default' ? 'custom' : 'default')}
                                      className="text-sm text-pink-600 hover:text-pink-800 font-medium flex items-center"
                                    >
                                      {colorType === 'default' ? (
                                        <>
                                          Override
                                          <Edit className="ml-1 h-4 w-4" />
                                        </>
                                      ) : (
                                        <>
                                          Reset to Default
                                          <RotateCcw className="ml-1 h-4 w-4" />
                                        </>
                                      )}
                                    </button>
                    </div>
                                  <div className="flex items-center space-x-2">
                                    <Select 
                                      value={colorType}
                                      onValueChange={(value) => setColorType(value)}
                                      disabled={true}
                                    >
                                      <SelectTrigger className="w-full opacity-50 cursor-not-allowed">
                                        <SelectValue>{colorType === 'default' ? 'Default' : 'Custom'}</SelectValue>
                                      </SelectTrigger>
                                      <SelectContent className="bg-white border border-gray-200 shadow-lg rounded-md overflow-hidden">
                                        <Scrollbars 
                                          style={{ height: 100 }} 
                                          autoHide
                                          autoHideTimeout={750}
                                          autoHideDuration={300}
                                          renderThumbVertical={({ style, ...props }) =>
                                            <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                                          }
                                        >
                                          <div className="py-1">
                                            <SelectItem value="default" className="py-2 px-4 cursor-pointer hover:bg-gray-100">
                                              <span className="font-medium text-gray-800 block">Default</span>
                                              <span className="text-sm text-gray-500 block">Use generated colors based on the selected style</span>
                                            </SelectItem>
                                            <SelectItem value="custom" className="py-2 px-4 cursor-pointer hover:bg-gray-100">
                                              <span className="font-medium text-gray-800 block">Custom</span>
                                              <span className="text-sm text-gray-500 block">Define your own base colors</span>
                                            </SelectItem>
                    </div>
                                        </Scrollbars>
                                      </SelectContent>
                                    </Select>
                      <Button
                        onClick={() => {
                                        const { baseColors: newBaseColors, displayColors: newDisplayColors } = generateColors(rows, columns, paletteStyle, pattern);
                                        setBaseColors(newBaseColors);
                                        setDisplayColors(newDisplayColors);
                                        setColorType('default'); // Set color type to default when randomizing
                                      }}
                                      className="h-10 px-3 bg-gray-100 hover:bg-gray-200 text-gray-600"
                                    >
                                      <Shuffle className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>

                                {/* Custom Color Pickers */}
                                {colorType === 'custom' && (
                                  <div className="space-y-2 mt-2">
                                    {uniqueColors.map((color, index) => (
                                      <div key={index} className="flex items-center space-x-2">
                                        <input
                                          type="color"
                                          value={color}
                                          onChange={(e) => handleBaseColorChange(index, e.target.value)}
                                          className="w-8 h-8 rounded-full"
                                        />
                                        <input
                                          type="text"
                                          value={color}
                                          onChange={(e) => handleBaseColorChange(index, e.target.value)}
                                          className="flex-1 px-2 py-1 border border-gray-300 rounded-md"
                                        />
                    </div>
                                    ))}
                    </div>
                                )}
                  </div>
                            )}
                    </div>

                          {/* Pattern */}
                          <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                            <div 
                              className="flex justify-between items-center p-4 cursor-pointer bg-white"
                              onClick={() => toggleSection('pattern')}
                            >
                              <h3 className="text-lg font-semibold">Pattern</h3>
                              <AnimatedChevron isOpen={expandedSections.pattern} />
                    </div>
                            {expandedSections.pattern && (
                              <div className="px-4 pb-4">
                                <div className="flex items-center">
                                  <div className="w-full flex-grow">
                                    <Select 
                                      value={pattern}
                                      onValueChange={(value) => handlePatternChange(value)}
                                      disabled={isPresetSelected}
                                    >
                                      <SelectTrigger className="w-full">
                                        <SelectValue>
                                          {patternDisplayNames[pattern]}
                                        </SelectValue>
                                      </SelectTrigger>
                                      <SelectContent className="p-0 bg-white border border-gray-200 shadow-lg overflow-hidden">
                                        <Scrollbars 
                                          style={{ height: 300 }} 
                                          autoHide
                                          autoHideTimeout={750}
                                          autoHideDuration={300}
                                          renderThumbVertical={({ style, ...props }) =>
                                            <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                                          }
                                        >
                                          <div className="py-0">
                                            {Object.entries(patternDisplayNames).map(([value, displayName]) => (
                                              <SelectItem 
                                                key={value} 
                                                value={value}
                                                className={cn(
                                                  "py-2 px-4 cursor-pointer transition-colors duration-150 ease-in-out relative",
                                                  "hover:bg-gray-100",
                                                  pattern === value && "bg-gray-100"
                                                )}
                                              >
                                                <div className="pr-8 h-[55px] flex flex-col justify-center">
                                                  <span className="font-medium text-gray-800 block">{displayName}</span>
                                                  <span className="text-sm text-gray-500 block leading-tight">{patternDefinitions[value]}</span>
                    </div>
                                                {pattern === value && (
                                                  <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                                                    <Check className="h-4 w-4 text-pink-500" />
                    </div>
                                                )}
                                              </SelectItem>
                                            ))}
                  </div>
                                        </Scrollbars>
                                      </SelectContent>
                                    </Select>
                    </div>
                      <Button
                        onClick={() => {
                                      const newPattern = getRandomPattern();
                                      handlePatternChange(newPattern);
                        }}
                                    className={`ml-2 h-10 px-3 bg-gray-100 hover:bg-gray-200 text-gray-600 ${isPresetSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={isPresetSelected}
                      >
                                    <Shuffle className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                            )}
                    </div>
                    </div>
                  </div>
                    </div>
                  </Scrollbars>
                    </div>

                {/* Pinned footer */}
                <div className="p-4 border-t border-gray-200">
                  <div className="relative" ref={buttonGroupRef}>
                    <div className="flex flex-row space-x-2">
                      <div className="flex-1 flex rounded-lg overflow-hidden border border-gray-300">
                        {/* Download button */}
                      <Button
                          onClick={handleExport}
                          className="flex-1 h-12 sm:h-16 text-sm sm:text-base bg-gray-100 hover:bg-gray-200 transition-colors duration-200 flex items-center justify-center px-2 sm:px-4 rounded-none"
                        >
                          <FileDown className="h-5 w-5 sm:h-6 sm:w-6 text-gray-600 mr-1 sm:mr-2 flex-shrink-0 stroke-[1.5]" />
                          <div className="flex flex-col items-start justify-center overflow-hidden">
                            <span className="text-gray-800 font-medium leading-tight truncate w-full">Download</span>
                            <span className="text-xs sm:text-sm text-gray-600 leading-tight truncate w-full">
                              {imageQuality} as {imageFormat.toUpperCase()}
                            </span>
                    </div>
                      </Button>
                        {/* Vertical divider */}
                        <div className="w-px bg-gray-300"></div>
                        {/* Copy to Clipboard button */}
                      <Button
                          onClick={handleCopyImageToClipboard}
                          className="h-12 sm:h-16 w-12 sm:w-16 bg-gray-100 hover:bg-gray-200 transition-colors duration-200 flex items-center justify-center rounded-none"
                        >
                          <Copy className="h-5 w-5 sm:h-6 sm:w-6 text-gray-600" />
                      </Button>
                    </div>
                      {/* Settings button with Popover */}
                      <Popover>
                        <PopoverTrigger asChild>
                      <Button
                            className="h-12 sm:h-16 w-12 sm:w-16 bg-black hover:bg-gray-900 transition-colors duration-200 rounded-lg flex items-center justify-center"
                          >
                            <Settings2 className="h-5 w-5 sm:h-6 sm:w-6 text-white" />
                      </Button>
                        </PopoverTrigger>
                        <PopoverContent 
                          className="p-6 bg-gray-950 rounded-xl shadow-lg"
                          align="end"
                          side="top"
                          sideOffset={5}
                          style={{ width: `${popoverWidth}px` }}
                        >
                          <div className="space-y-5">
                            <h2 className="text-lg font-semibold text-white mb-3">Export Settings</h2>
                            <div>
                              <h3 className="font-medium text-sm text-white mb-2">Format</h3>
                              <div className="grid grid-cols-2 gap-2">
                                {['PNG', 'JPEG'].map((format) => (
                                  <motion.button
                                    key={format}
                                    onClick={() => setImageFormat(format.toLowerCase())}
                                    className={cn(
                                      "h-10 rounded-md border text-sm font-medium",
                                      imageFormat === format.toLowerCase() 
                                        ? "border-pink-500 bg-gray-800 text-white" 
                                        : "border-gray-800 bg-gray-900 text-gray-300"
                                    )}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    transition={{ duration: 0.2 }}
                                  >
                                    {format}
                                  </motion.button>
                                ))}
                    </div>
                  </div>
                            <div>
                              <h3 className="font-medium text-sm text-white mb-2">Quality</h3>
                              <div className="grid grid-cols-3 gap-2">
                                {['1x', '2x', '3x'].map((quality) => (
                                  <motion.button
                                    key={quality}
                        onClick={() => {
                                      setImageQuality(quality);
                                      const multiplier = parseInt(quality);
                                      setOutputResolution({
                                        width: baseResolution.width * multiplier,
                                        height: baseResolution.height * multiplier
                                      });
                                    }}
                                    className={cn(
                                      "h-10 rounded-md border text-sm font-medium",
                                      imageQuality === quality 
                                        ? "border-pink-500 bg-gray-800 text-white" 
                                        : "border-gray-800 bg-gray-900 text-gray-300"
                                    )}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    transition={{ duration: 0.2 }}
                                  >
                                    {quality}
                                  </motion.button>
                                ))} 
                    </div>
                    </div>
                            <div className="pt-2">
                              <div className="flex justify-between items-center">
                                <h3 className="font-medium text-sm text-white">Output Resolution</h3>
                                <div className="text-sm text-gray-300">
                                  {outputResolution.width} x {outputResolution.height}
                  </div>
                    </div>
                    </div>
                  </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                    </div>
                  </div>
                    </div>

             {/* Center section with preview */}
             <div className="w-full lg:w-1/2 flex flex-col order-1 lg:order-2">
                <div className="p-4 flex-grow flex flex-col items-center justify-center">
                  <div 
                    ref={containerRef}
                    className="relative bg-white rounded-lg overflow-hidden flex items-center justify-center shadow-sm"
                    style={{ 
                      width: 'calc(100% + 30px)',
                      paddingBottom: 'calc(100% + 30px)',
                    }}
                  >
                    <div 
                      className="absolute inset-0 bg-white rounded-lg shadow-md overflow-hidden flex items-center justify-center"
                    >
                      {isGridInitialized ? (
                        <div
                          style={{
                            width: `${100 * (outputResolution.width / Math.max(outputResolution.width, outputResolution.height))}%`,
                            height: `${100 * (outputResolution.height / Math.max(outputResolution.width, outputResolution.height))}%`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: `repeat(${columns}, 1fr)`,
                              gridTemplateRows: `repeat(${rows}, 1fr)`,
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            {displayColors.map((color, index) => (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: color,
                                  width: '100%',
                                  height: '100%',
                                }}
                              />
                            ))}
                    </div>
                  </div>
                      ) : (
                        <div className="flex items-center justify-center h-full">
                          <p>Generating color grid...</p>
                    </div>
                      )}
                    </div>
                    
                    {/* Success and Loading message overlay */}
                    <AnimatePresence>
                      {(successMessage || loadingMessage) && (
                        <motion.div
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className="absolute top-4 left-0 right-0 mx-auto w-max bg-black bg-opacity-100 text-white px-4 py-2 rounded-full shadow-lg flex items-center space-x-2 whitespace-nowrap"
                        >
                          <span>{loadingMessage || successMessage}</span>
                          {loadingMessage ? (
                            <Loader2 className="h-4 w-4 animate-spin" />
                          ) : (
                            <Check className="h-4 w-4 text-green-500" />
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>

                    {/* Bottom controls container */}
                    <div className="absolute bottom-2 left-2 right-2 flex justify-between items-center">
                      {/* Resolution display */}
                      <div className="relative">
                        <button 
                          onClick={() => setShowInfo(!showInfo)}
                          className="bg-black text-white px-3 py-2 rounded-full text-sm font-medium flex items-center h-10"
                        >
                          <span className="mr-1">
                            {outputResolution.width} x {outputResolution.height}
                          </span>
                          <AnimatedChevron isOpen={showInfo} className="w-4 h-4" />
                        </button>
                        {showInfo && (
                          <div className="absolute left-0 bottom-full mb-2 space-y-1">
                            <div className="bg-black text-white px-3 py-2 rounded-full text-sm font-medium whitespace-nowrap h-10 flex items-center" style={{ width: 'max-content' }}>
                              {aspectRatio} (Aspect Ratio)
                    </div>
                            <div className="bg-black text-white px-3 py-2 rounded-full text-sm font-medium whitespace-nowrap h-10 flex items-center" style={{ width: 'max-content' }}>
                              {rows} {rows === 1 ? 'Row' : 'Rows'} x {columns} {columns === 1 ? 'Column' : 'Columns'} ({rows * columns} Cells)
                  </div>
                            <div className="bg-black text-white px-3 py-2 rounded-full text-sm font-medium whitespace-nowrap h-10 flex items-center" style={{ width: 'max-content' }}>
                              {paletteStyleDisplayNames[paletteStyle]}
                    </div>
                            <div className="bg-black text-white px-3 py-2 rounded-full text-sm font-medium whitespace-nowrap h-10 flex items-center" style={{ width: 'max-content' }}>
                              {patternDisplayNames[pattern]}
                    </div>
                  </div>
                        )}
                    </div>

                      {/* Randomize All button */}
                      <motion.button
                        onClick={randomizeAll}
                        className="bg-black text-white px-3 py-2 rounded-full text-sm font-medium flex items-center space-x-2 h-10"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Shuffle className="h-4 w-4" />
                        <span>Randomize All</span>
                      </motion.button>
                    </div>
                  </div>
                    </div>
                    </div>

              {/* Right sidebar */}
              <div className="w-full lg:w-1/4 bg-white rounded-lg shadow-sm flex flex-col order-3">
                <div className="p-4 h-full flex flex-col">
                  <h2 className="text-2xl font-semibold text-gray-900 mb-4 ml-2">Presets</h2>
                  <div className="relative flex-grow overflow-hidden h-[60vh] lg:h-auto"> {/* Added fixed height for mobile */}
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                      renderThumbVertical={({ style, ...props }) =>
                        <div {...props} style={{ ...style, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '3px' }}/>
                      }
                      style={{ paddingRight: '8px' }}
                    >
                      <div className="flex flex-wrap lg:flex-col gap-2 pr-4 pl-2 pt-2">
                        {presets.map((preset, index) => (
                          <div key={index} className="w-[calc(50%-4px)] lg:w-full mb-2">
                            {renderPresetMobile(preset, index)}
                          </div>
                        ))}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Divider */}
        <div className="border-t border-gray-200 mt-4 mb-8"></div>

        {/* FAQ Section */}
        <section className="bg-gray-50 pt-0 pb-8">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-gray-900 mb-7 text-center">Frequently Asked Questions</h2>
            <div className="space-y-6">
              {[
                {
                  question: "What is a Color Palette Grid Generator?",
                  answer: "A Color Palette Grid Generator is a tool that creates a grid of colors based on various parameters such as style, pattern, and dimensions. It's useful for designers, artists, and anyone working with color schemes to quickly generate visually appealing color combinations in a grid format."
                },
                {
                  question: "How do I use this Color Palette Grid Generator?",
                  answer: "To use this generator, adjust the settings in the left panel. You can change the grid size, color style, pattern, and more. The preview updates in real-time. Once satisfied, you can download your creation or copy it to the clipboard. Experiment with different settings to find the perfect color grid for your project."
                },
                {
                  question: "Can I customize the colors in the grid?",
                  answer: "Yes, you can customize the colors by selecting 'Custom' in the Color Type dropdown. This allows you to choose your own base colors for the grid. You can then fine-tune each color using the color pickers provided, giving you full control over your color palette."
                },
                {
                  question: "What file formats can I download the grid in?",
                  answer: "You can download the color grid as a PNG or JPEG file. The format can be selected in the export settings. PNG is best for designs requiring transparency or sharp edges, while JPEG is suitable for photographs or designs with many color gradations."
                },
                {
                  question: "Is this tool free to use?",
                  answer: "Yes, this Color Palette Grid Generator is completely free to use. We only ask for your email to provide you with free access and to send our weekly newsletter for creatives. The newsletter includes design tips, resources, and inspiration that complement the use of this tool."
                }
              ].map((faq, index) => (
                <div key={index} className="bg-white shadow-sm rounded-lg overflow-hidden p-4">
                  <h3 className="text-lg font-medium text-gray-900">{faq.question}</h3>
                  <p className="text-gray-600 mt-2">{faq.answer}</p>
                    </div>
              ))}
                  </div>
                    </div>
        </section>

        {/* Beehiiv recommendations widget */}
        {showRecommendations && (
          <div id="beehiiv-recommendations-widget"></div>
        )}
                    </div>
    </TooltipProvider>
  );
};

export default ColorGridGenerator;